import { DataTypeProviderInstance } from "../../businessLogic/dataTypes/DataTypeProvider";
import { IDataTypeInfo } from "../../businessLogic/dataTypes/IDataTypeInfo";
import { IStepState } from "./IStepState";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";

/**
 * Contains helpers to read query execution state.
 */
export class QueryExecutionInspector {
    /**
     * Gets the input data for the given step.
     * @param index The step for which to get input.
     * @param steps The state for all the steps.
     */
    public static getStepInput(index: number, steps: IStepState[]): string {
        const activeStep = steps[index];
        let inputText = activeStep.input.data;
        if (activeStep.input.sourceType === SourceType.previousStepOutput) {
            inputText = steps[activeStep.input.sourceStepIndex as number].output.result;
        }

        return inputText;
    }

    /**
     * Get the input data type for the given step.
     * @param index The step for which to get input data type.
     * @param steps The state for all the steps.
     */
    public static getStepInputDataInfo(index: number, steps: IStepState[]): IDataTypeInfo {
        const activeStep = steps[index];
        let inputType = DataTypeProviderInstance.dataTypeInfoDictionary[activeStep.input.dataType];
        if (activeStep.input.sourceType === SourceType.previousStepOutput) {
            inputType = QueryExecutionInspector.getStepOutputDataInfo(activeStep.input.sourceStepIndex as number, steps);
        }

        return inputType;
    }

    /**
     * Get the output data type for the given step.
     * @param index The step for which to get output data type.
     * @param steps The state for all the steps.
     */
    public static getStepOutputDataInfo(index: number, steps: IStepState[]): IDataTypeInfo {
        const activeStep = steps[index];
        let transformType = QueryInfoProviderInstance.queryInfoDictionary[activeStep.transform.transformType];
        return DataTypeProviderInstance.dataTypeInfoDictionary[transformType.outputDataType];
    }
}