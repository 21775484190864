import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class Xml2Js_ToXmlQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "//dictionary/c";
    }

    public name: QueryType = QueryType.xml2js_toxml;

    public displayName: string = "JSON to XML (xml2js 0.4.23)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Xml;

    public readonly hasQuery: boolean = false;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "//";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://www.npmjs.com/package/xml2js";

    public editorMode: string = "text";
}