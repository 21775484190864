import { IActionHandlers, createReducerClass } from "../../infrastructure";

import { IStepSettingsState } from "./IStepSettingsState";
import { StepSettingsActions } from "./StepSettingsActions";

const StepSettingsActionHandlers: IActionHandlers<IStepSettingsState, StepSettingsActions> = {
    setVisibility: (clonedState, visible) => {
        clonedState.visible = visible;
    },
    setupModal: (clonedState, stepIndex, title, selectedSourceType, selectedSourceStep, selectedInputDataType, selectedTransformType) => {
        clonedState.editStepIndex = stepIndex;
        clonedState.enteredTitle = title;
        clonedState.selectedSourceType = selectedSourceType;
        clonedState.selectedSourceType = selectedSourceType;
        clonedState.selectedSourceStep = selectedSourceStep;
        clonedState.selectedInputDataType = selectedInputDataType;
        clonedState.selectedTransformType = selectedTransformType;
        clonedState.visible = true;
    },
    setEditStepIndex: (clonedState, index) => {
        clonedState.editStepIndex = index;
    },
    setEnteredTitle: (clonedState, title) => {
        clonedState.enteredTitle = title;
    },
    setSelectedSourceType: (clonedState, selectedSourceType) => {
        clonedState.selectedSourceType = selectedSourceType;
    },
    setSelectedSourceStep: (clonedState, selectedSourceStep) => {
        clonedState.selectedSourceStep = selectedSourceStep;
    },
    setSelectedInputDataType: (clonedState, selectedInputDataType) => {
        clonedState.selectedInputDataType = selectedInputDataType;
    },
    setSelectedTransformType: (clonedState, selectedTransformType) => {
        clonedState.selectedTransformType = selectedTransformType;
    },
}

/**
 * Reducer to handle state updates for the add step modal.
 */
export const StepSettingsReducer = createReducerClass(StepSettingsActionHandlers);