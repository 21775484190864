import { ISourceTypeInfo } from "./ISourceTypeInfo";
import { ManualSourceTypeInfo } from "./ManualSourceTypeInfo";
import { PreviousStepOutputSourceTypeInfo } from "./PreviousStepOutputSourceTypeInfo";

export class SourceTypeProvider {
    public readonly sourceTypeInfoArray: ISourceTypeInfo[] = [];
    public readonly sourceTypeInfoDictionary: { [name: string]: ISourceTypeInfo } = {};

    constructor() {
        this.sourceTypeInfoArray.push(new ManualSourceTypeInfo());
        this.sourceTypeInfoArray.push(new PreviousStepOutputSourceTypeInfo());
        ////this.sourceTypeInfoArray.push(new RestDownloadSourceTypeInfo());

        for (var i = 0; i < this.sourceTypeInfoArray.length; i++) {
            this.sourceTypeInfoDictionary[this.sourceTypeInfoArray[i].sourceType] = this.sourceTypeInfoArray[i];
        }
    }
}

export const SourceTypeProviderInstance = new SourceTypeProvider();