import { FormControl, FormControlLabel, ListItemIcon, Switch, Theme, createStyles, makeStyles } from '@material-ui/core';

import { AppBar } from '@material-ui/core';
import { AppRouting } from '../../AppRouting';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import HelpIcon from '@material-ui/icons/Help';
import { ITopNavProps } from './ITopNavProps';
import { IconButton } from '@material-ui/core';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Menu } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { Toolbar } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      cursor: 'pointer'
    },
    subTitle: {
      marginLeft: 10,
      fontSize: 12,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    fbLike: {
      marginRight: 20
    },
    pageLayout: {
      marginRight: 20
    },
  }),
);

export default function TopNav(props: ITopNavProps) {
  const classes = useStyles();
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => {history.push(AppRouting.creditsPath);}}>
        <ListItemIcon aria-label="open credits page" color="inherit">
          <LoyaltyIcon />
        </ListItemIcon>
        <p>Credits</p>
      </MenuItem>
      <MenuItem onClick={() => {history.push(AppRouting.privacyPath);}}>
        <ListItemIcon aria-label="open Privacy page" color="inherit">
          <DataUsageIcon />
        </ListItemIcon>
        <p>Privacy</p>
      </MenuItem>
      <MenuItem onClick={() => {history.push(AppRouting.helpPath);}}>
        <ListItemIcon aria-label="open help page" color="inherit">
          <HelpIcon />
        </ListItemIcon>
        <p>Help</p>
      </MenuItem>
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  let layoutToggle = (<></>);
  if (props.showLayoutToggle) {
    layoutToggle = (
      <FormControl component="fieldset" className={classes.pageLayout}>
      <Tooltip title="Page layout">
        <FormControlLabel
          control={<Switch checked={props.horizontalLayout} onChange={props.onLayoutToggle} name="layout" color="default" />}
          label={props.horizontalLayout ? "Horizontal" : "Vertical"}
          labelPlacement="start"
        />
      </Tooltip>
    </FormControl>);
  }

  let drawerToggle = (<></>);
  if (props.showDrawerToggle) {
    drawerToggle = (
      <Tooltip title="Toggle step navigation">
      <IconButton 
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={props.onDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>);
  }

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {drawerToggle}
          <Typography className={classes.title} variant="h6" noWrap onClick={() => {history.push(AppRouting.homePath);}}>
            {props.appTitle}
            <span className={classes.subTitle}>{props.subTitle}</span>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div className={classes.fbLike}>
              <div className="fb-like" data-href="http://www.jsonquerytool.com" data-layout="button_count" data-action="like" data-show-faces="true" data-share="true"></div>
            </div>
          </div>
          {layoutToggle}
          <div className={classes.sectionDesktop}>
            <Tooltip title="Credits">
              <IconButton aria-label="open credits page" color="inherit" onClick={() => {history.push(AppRouting.creditsPath);}}>
                <LoyaltyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Privacy">
              <IconButton aria-label="open privacy page" color="inherit" onClick={() => {history.push(AppRouting.privacyPath);}}>
                <DataUsageIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Help">
              <IconButton aria-label="open help page" color="inherit" onClick={() => {history.push(AppRouting.helpPath);}}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
