import { EmptyActions, EmptyReducer, Experience, OrUndefined, createMemoizedFunction } from "../../infrastructure";

import { DataTypeProviderInstance } from "../../businessLogic/dataTypes/DataTypeProvider";
import { Dispatch } from "redux";
import { IStepProps } from "../../components/workflow/IStepProps";
import { IStepState } from "../../state/queryExecution/IStepState";
import { IWorkflowProps } from "../../components/workflow/IWorkflowProps";
import { QueryExecutionInspector } from "../../state/queryExecution/QueryExecutionInspector";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { QueryType } from "../../businessLogic/queryTypes/QueryType";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";
import { SourceTypeProviderInstance } from "../../businessLogic/sourceTypes/SourceTypeProvider";

type Settings = {
    actionMap: EmptyActions,
    children: {},
    parentCalls: {
        getCurrentStepIndex: () => number,
        getStepsState: () => IStepState[],
        onInputFormatClicked: (index: number) => void,
        onStepSettingsClicked: (index: number) => void,
        onAddStepClicked: () => void,
        onDeleteStepClicked: (index: number) => void
        onCurrentStepChanged: (index: number) => void,
        onTransformTypeChanged: (index: number, transformType: string) => void
    }
};

/**
 * Contains the UI logic to control the workflow pane.
 */
export class WorkflowExperience extends Experience<{}, IWorkflowProps, Settings> {
    /**
     * Initializes a new instance of the `WorkflowExperience` class.
     * @param namespace A namespace to differentiate this experience from siblings.
     */
    constructor(
        namespace: string) {
        super(namespace, EmptyActions, EmptyReducer, {}, [], []);

        this.mapSteps = createMemoizedFunction(this.mapSteps);
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapStateToProps(state: {}, ownProps: {}): OrUndefined<IWorkflowProps> {
        const stepsState = this.parentCalls.getStepsState();
        return {
            selectedStepIndex: this.parentCalls.getCurrentStepIndex(),
            steps: this.mapSteps(stepsState),
            onDeleteClicked: undefined,
            onInputFormatClicked: undefined,
            onStepSettingsClicked: undefined,
            onAddStepClicked: undefined,
            onStepClicked: undefined,
            onTransformTypeChanged: undefined
        };
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapDispatchToProps(dispatch: Dispatch, ownProps: {}): OrUndefined<IWorkflowProps> {
        return {
            selectedStepIndex: undefined,
            steps: undefined,
            onDeleteClicked: this.parentCalls.onDeleteStepClicked,
            onInputFormatClicked: this.parentCalls.onInputFormatClicked,
            onStepSettingsClicked: this.parentCalls.onStepSettingsClicked,
            onAddStepClicked: this.parentCalls.onAddStepClicked,
            onStepClicked: this.parentCalls.onCurrentStepChanged,
            onTransformTypeChanged: this.parentCalls.onTransformTypeChanged,
        };
    }

    /**
     * Map the steps state to the steps props.
     */
    private mapSteps(stepsState: IStepState[]): IStepProps[] {
        return stepsState.map((value, index) => {
            const queryInfo = QueryInfoProviderInstance.queryInfoDictionary[value.transform.transformType];
            const queryInputDataTypeInfo = DataTypeProviderInstance.dataTypeInfoDictionary[queryInfo.inputDataType];
            const inputSourceInfo = SourceTypeProviderInstance.sourceTypeInfoDictionary[value.input.sourceType];
            const manualEntryDataTypeInfo = DataTypeProviderInstance.dataTypeInfoDictionary[value.input.dataType];

            let hasInputAndTransformTypeMismatch: boolean = manualEntryDataTypeInfo.dataType !== queryInputDataTypeInfo.dataType;
            let inputSource: string = inputSourceInfo.displayName;
            let inputType: string = manualEntryDataTypeInfo.displayName;

            // If the input is coming from a previous step, determine the previous steps name,
            // and check if the output type from the previous step matches the required input type of this step.
            if (inputSourceInfo.sourceType === SourceType.previousStepOutput) {
                const previousStep = stepsState[value.input.sourceStepIndex as number];
                const previousStepQueryInfo = QueryInfoProviderInstance.queryInfoDictionary[previousStep.transform.transformType];
                const previousStepOutputDataTypeInfo = DataTypeProviderInstance.dataTypeInfoDictionary[previousStepQueryInfo.outputDataType];
                inputSource = previousStep.title;
                hasInputAndTransformTypeMismatch = previousStepOutputDataTypeInfo.dataType !== queryInputDataTypeInfo.dataType;
                inputType = previousStepOutputDataTypeInfo.displayName;
            }
            return {
                transformTypes: QueryInfoProviderInstance.buildFilteredTransformTypes(
                    QueryExecutionInspector.getStepInputDataInfo(index, stepsState).dataType,
                    value.transform.transformType as QueryType),
                inputSource: inputSource,
                inputType: inputType,
                inputFormatEnabled: queryInputDataTypeInfo.canFormat && value.input.sourceType === SourceType.manualEntry,
                title: value.title,
                transformType: queryInfo.name,
                deleteEnabled: stepsState.length > 1 && stepsState.find(x => x.input.sourceType === SourceType.previousStepOutput && x.input.sourceStepIndex === index) == null,
                transformLanguageHelpLink: queryInfo.helpUrl,
                hasInputAndTransformTypeMismatch: hasInputAndTransformTypeMismatch
            };
        });
    }
}