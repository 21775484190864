import { DataType } from "../dataTypes/DataType";
import { IQueryInfo } from "./info/IQueryInfo";
import { JSONPathDChesterQueryInfo } from "./info/JSONPathDChesterQueryInfo";
import { JSONPathPlusQueryInfo } from "./info/JSONPathPlusQueryInfo";
import { JSONPathQueryInfo } from "./info/JSONPathQueryInfo";
import { JSONPointerQueryInfo } from "./info/JSONPointerQueryInfo";
import { JSONataQueryInfo } from "./info/JSONataQueryInfo";
import { JSPathQueryInfo } from "./info/JSPathQueryInfo";
import { LodashQueryInfo } from "./info/LodashQueryInfo";
import { QueryType } from "./QueryType";
import { SimpleJSQueryInfo } from "./info/SimpleJSQueryInfo";
import { SimpleXPathQueryInfo } from "./info/SimpleXPathQueryInfo";
import { SimpleXsltQueryInfo } from "./info/SimpleXsltQueryInfo";
import { UnderscoreQueryInfo } from "./info/UnderscoreQueryInfo";
import { Xml2Js_ToJsonQueryInfo } from "./info/Xml2js_ToJsonQueryInfo";
import { Xml2Js_ToXmlQueryInfo } from "./info/Xml2js_ToXmlQueryInfo";
import { createAllInputsMemoizedFunction } from "../../infrastructure/memoizers";

export class QueryInfoProvider {
    public readonly queryInfoArray: IQueryInfo[] = [];
    public readonly queryInfoDictionary: { [name: string]: IQueryInfo } = {};

    private readonly queryTypesByInputDataType: { [dataType: string]: IQueryInfo[] } = {};

    constructor() {
        this.queryInfoArray.push(new JSONPathDChesterQueryInfo());
        this.queryInfoArray.push(new JSONPathPlusQueryInfo());
        this.queryInfoArray.push(new JSONPathQueryInfo());
        this.queryInfoArray.push(new JSONataQueryInfo());
        this.queryInfoArray.push(new JSONPointerQueryInfo());
        this.queryInfoArray.push(new JSPathQueryInfo());
        ////this.queryInfoArray.push(new JPathQueryInfo());
        this.queryInfoArray.push(new LodashQueryInfo());
        this.queryInfoArray.push(new UnderscoreQueryInfo());
        this.queryInfoArray.push(new SimpleJSQueryInfo());
        ////this.queryInfoArray.push(new XmlJs_ToXmlQueryInfo());
        this.queryInfoArray.push(new Xml2Js_ToXmlQueryInfo());
        this.queryInfoArray.push(new Xml2Js_ToJsonQueryInfo());
        this.queryInfoArray.push(new SimpleXPathQueryInfo());
        this.queryInfoArray.push(new SimpleXsltQueryInfo());
    
        for (let i = 0; i < this.queryInfoArray.length; i++) {
            const queryInfo = this.queryInfoArray[i];
            this.queryInfoDictionary[queryInfo.name] = queryInfo;

            if (!this.queryTypesByInputDataType[queryInfo.inputDataType]) {
                this.queryTypesByInputDataType[queryInfo.inputDataType] = [];
            }

            this.queryTypesByInputDataType[queryInfo.inputDataType].push(queryInfo);
        }

        this.buildFilteredTransformTypes = createAllInputsMemoizedFunction(this.buildFilteredTransformTypes);
    }

    /**
     * Get the query types that take the given data type as an input value.
     * @param dataType The data type for which to get query types.
     */
    public getByInputDataType(dataType: DataType): IQueryInfo[]  {
        return this.queryTypesByInputDataType[dataType];
    }

    /**
     * Build an array of transform types from the list of registered query info providers filtered by input data type.
     * @param inputDataType The input data type for the step.
     * @param currentTransformType The currently selected transform type.
     */
    public buildFilteredTransformTypes(inputDataType: DataType, currentTransformType: QueryType | null): { key: QueryType, description: string }[] {
        let currentTransformTypeAvailableForInputType = false;
        const transformTypes = QueryInfoProviderInstance.getByInputDataType(inputDataType).map((info) => {
            currentTransformTypeAvailableForInputType = currentTransformTypeAvailableForInputType || info.name === currentTransformType;
            return { key: info.name, description: info.displayName };
        });

        if (!currentTransformTypeAvailableForInputType && currentTransformType !== null) {
            transformTypes.push({ key: currentTransformType, description: QueryInfoProviderInstance.queryInfoDictionary[currentTransformType].displayName });
        }

        return transformTypes;
    }
}

export const QueryInfoProviderInstance = new QueryInfoProvider();