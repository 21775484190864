import { FormControl, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IWorkflowProps } from './IWorkflowProps';
import IconButton from '@material-ui/core/IconButton';
import InputIcon from '@material-ui/icons/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import TransformIcon from '@material-ui/icons/Transform';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        transformSelect: {
            width: 180
        }
    }),
);

export default function Workflow(props: IWorkflowProps) {
    const classes = useStyles();

    const steps = props.steps.map((value, index) => {
        const transformMenuItems = value.transformTypes.map((item) => {
            return (<MenuItem key={item.key} value={item.key}>{item.description}</MenuItem>);
        });

        let mismatchError = <></>;
        if (value.hasInputAndTransformTypeMismatch) {
            mismatchError = (
                <Alert variant="filled" severity="error">
                    Input and transform type mismatch!
                </Alert>)
        }

        return (
            <div key={index}>
                <ListItem button selected={index === props.selectedStepIndex} onClick={() => props.onStepClicked(index)}>
                    <ListItemIcon>
                        <SwapHorizIcon />
                    </ListItemIcon>
                    <ListItemText primary={value.title} />
                    <ListItemSecondaryAction>
                        <Tooltip title="Step settings">
                            <IconButton aria-label="Step settings" color="primary" onClick={(event) => { props.onStepSettingsClicked(index); }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete step">
                            <span>
                                <IconButton aria-label="delete" color="primary" disabled={!value.deleteEnabled} onClick={(event) => { props.onDeleteClicked(index); }}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
                <List component="div" disablePadding>
                    {mismatchError}
                    <ListItem className={classes.nested}>
                        <ListItemIcon>
                            <InputIcon />
                        </ListItemIcon>
                        <ListItemText primary="Input" secondary={`${value.inputType}/${value.inputSource}`} />
                        <ListItemSecondaryAction>
                            <Tooltip title="Format input data">
                                <span>
                                    <IconButton aria-label="Format input data" color="primary" disabled={!value.inputFormatEnabled} onClick={(event) => { props.onInputFormatClicked(index); }}>
                                        <FormatAlignLeftIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemIcon>
                            <TransformIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transform" secondary={<React.Fragment>
                            <FormControl>
                                <Select
                                    className={classes.transformSelect}
                                    id="transform-select"
                                    value={value.transformType}
                                    onChange={(event) => { props.onTransformTypeChanged(index, event.target.value as string); }}
                                >
                                    {transformMenuItems}
                                </Select>
                            </FormControl>
                        </React.Fragment>} />
                        <ListItemSecondaryAction>
                            <Tooltip title="Help for transform language">
                                <a target="_blank" rel="noopener noreferrer" href={value.transformLanguageHelpLink}>
                                    <IconButton aria-label="Help for transform language" color="primary">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Divider />
            </div>
        );
    });

    return (
        <List
            component="nav"
            className={classes.root}
        >
            {steps}
            <ListItem>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={props.onAddStepClicked}>
                    Add step
                </Button>
            </ListItem>
        </List>
    );
}
