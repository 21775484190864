import { ActionClass } from "../../infrastructure";
import { DataType } from "../../businessLogic/dataTypes/DataType";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";

export class QueryExecutionActions extends ActionClass {
    public setActiveStepIndex: (index: number) => void = null as any;
    public setActiveInputCode: (code: string) => void = null as any;
    public setActiveTransformCode: (code: string) => void = null as any;
    public setActiveOutput: (result: string, hasError: boolean) => void = null as any;

    public setOutput: (stepIndex: number, result: string, hasError: boolean) => void = null as any;
    public setTransformType: (stepIndex: number, transformType: string) => void = null as any;
    public setTransformCode: (stepIndex: number, code: string) => void = null as any;

    public addStep: (title: string, sourceType: SourceType, sourceDataType: DataType, sourceStepIndex: number, transformType: string, transformCode: string) => void = null as any;
    public updateStep: (index: number, title: string, sourceType: SourceType, sourceDataType: DataType, sourceStepIndex: number, transformType: string, transformCode: string) => void = null as any;
    public deleteStep: (index: number) => void = null as any;
}