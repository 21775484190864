import { IActionHandlers, createReducerClass } from "../../infrastructure";

import { AppActions } from "./AppActions";
import { IAppState } from "./IAppState";

const AppActionHandlers: IActionHandlers<IAppState, AppActions> = {
    setHorizontalLayout: (clonedState, horizontal) => {
        clonedState.horizontalLayout = horizontal;
    },
    setDrawerOpen: (clonedState, open) => {
        clonedState.drawerOpen = open;
    },
    setCookieState: (clonedState, visible) => {
        clonedState.cookieAlertVisible = visible;
    },
}

export const AppReducer = createReducerClass(AppActionHandlers);