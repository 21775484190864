import { Dispatch } from "redux";

/**
 * The type of a set of action parameter definitions by name.
 */
export type IActionClass = {
    [actionName: string]: (...params: any[]) => void;
}

/**
 * The base class for a type of a set of action parameter definitions by name.
 */
export class ActionClass implements IActionClass {
    [key: string]: (...params: any[]) => void;
}

/**
 * The action sender methods for actions.
 */
export type IActionSenders<TActionMap extends IActionClass> = {
    [P in keyof TActionMap]: (...params: Parameters<TActionMap[P]>) => void;
}

/**
 * Creates action senders from the given action map.
 */
export function createActions<TActionClass extends IActionClass>(dispatch: Dispatch, namespace: string, actionMap: TActionClass): IActionSenders<TActionClass> {
    const actions: IActionSenders<TActionClass> = {} as any;
    
    for (const [key] of Object.entries(actionMap)) {
        const castKey = key as keyof TActionClass;
        actions[castKey] = function() {
            dispatch({
                type: namespace + "." + key,
                payload: arguments
            })
        }
    }

    return actions;
}

/**
 * An empty action implementation for experiences that don't need any actions.
 */
export class EmptyActions extends ActionClass {}
