import * as React from "react";

import { Route, BrowserRouter as Router, useRouteMatch } from "react-router-dom";
import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { AppRouting } from "../../AppRouting";
import CookieDialog from "./CookieDialog";
import { CreditsPage } from "../creditsPage/CreditsPage";
import { CssBaseline } from '@material-ui/core';
import { HelpPage } from "../helpPage/HelpPage";
import { IAppProps } from "./IAppProps";
import { PrivacyPage } from "../privacyPage/PrivacyPage";
import TopNav from '../topNav/TopNav';
import { sampleData } from "../../businessLogic/queryTypes/sampleData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBarSpacer: theme.mixins.toolbar,
  }),
);

/**
 * Renders the app.
 * @param props The component properties.
 */
export default function App(props: IAppProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Router>
                <CookieDialog cookieAlertVisible={props.cookieAlertVisible} onCookieAcceptClick={props.onCookieAcceptClick} />
                <Route exact path={AppRouting.homePath} render={() => {
                    return (
                      <>
                        <RenderTopNav {...props} />
                        {React.createElement(props.queryPageComponent)}
                      </>);
                }} />
                <Route exact path={AppRouting.samplePath} render={(routeProps) => {
                    // Redirect to root if the sample data option doesn't exist.
                    const chosenData: any = sampleData[routeProps.match.params.id];
                    if (!chosenData) {
                        routeProps.history.push("/");
                    }

                    props.onSamplePageEnter(routeProps.match.params.id);
                    return (
                      <>
                        <RenderTopNav {...props} />
                        {React.createElement(props.queryPageComponent)}
                      </>);
                }} />
                <Route path={AppRouting.helpPath} render={() => {
                    return (
                      <>
                        <RenderTopNav {...props} />
                        <HelpPage/>
                      </>);
                }} />
                <Route path={AppRouting.privacyPath} render={() => {
                    return (
                      <>
                        <RenderTopNav {...props} />
                        <PrivacyPage/>
                      </>);
                }} />
                <Route path={AppRouting.creditsPath} render={() => {
                    return (
                      <>
                        <RenderTopNav {...props} />
                        <CreditsPage/>
                      </>);
                }} />
            </Router>
        </div>
    );
}

function RenderTopNav(props: IAppProps) {
  let { path } = useRouteMatch();
  return (
    <TopNav
      appTitle="{ } JSON Query"
      subTitle="Now with XML!"
      onDrawerToggle={props.onDrawerToggle}
      horizontalLayout={props.horizontalLayout}
      onLayoutToggle={props.onLayoutToggle}
      showDrawerToggle={path === AppRouting.homePath}
      showLayoutToggle={path === AppRouting.homePath} />);
}