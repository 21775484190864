import { DataType } from "./DataType";
import { IDataTypeInfo } from "./IDataTypeInfo";

export class JSONDataTypeInfo implements IDataTypeInfo {
    public readonly dataType: DataType = DataType.Json;

    public readonly canFormat: boolean = true;

    readonly displayName: string = "JSON";

    readonly editorType: string = "json";

    public format(data: string): string {
        const parsedJson = JSON.parse(data);
        return JSON.stringify(parsedJson, null, 4);
    }
}