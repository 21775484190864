import { Action } from "redux";
import { IReducer } from "./IReducer";
import { clone } from "lodash";

export type CompoundReducerParam<TParentState, TState> = {
    reducer: IReducer<TState>,
    stateGetter?: (parentState: TParentState) => TState;
    stateSetter?: (parentState: TParentState, state: TState) => void;
}

export class CompoundReducer<TState> implements IReducer<TState> {
    constructor(private reducers: CompoundReducerParam<TState, any>[]) {
    }

    public reduce(state: TState | undefined, action: Action<string>): TState {

        this.reducers.forEach(reducer => {
            if (state !== null && state !== undefined)
            {
                const originalChildState = reducer.stateGetter ? reducer.stateGetter(state) : state;
                const newChildState = reducer.reducer.reduce(originalChildState, action);
                if (originalChildState !== newChildState) {
                    state = clone(state);
                    if (reducer.stateSetter) {
                        reducer.stateSetter(state, newChildState);
                    } else {
                        state = newChildState;
                    }
                }
            }
        });

        return state || {} as any;
    }
}