import * as React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import { ICookieDialogProps } from "./ICookieDialogProps";

/**
 * Renders the cookie acceptance dialog.
 * @param props The component properties.
 */
export default function CookieDialog(props: ICookieDialogProps) {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={props.cookieAlertVisible}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">Cookies</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div><p>We use technologies, such as cookies, to customise content and advertising, to provide
                    social media features and to analyse traffic to the site. We also share information about
                    your use of our site with our trusted social media, advertising and analytics partners.
                    For more information see <a href="https://www.google.com/policies/technologies/partner-sites/">Google partner sites terms and conditions</a> and <a href="https://www.facebook.com/about/privacy">Facebook privacy policy</a>.</p>

                    <p>By continuing to use this site you consent to our cookies on this device or 
                    you can change your browser settings to not allow cookies.</p></div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCookieAcceptClick} color="primary">Accept</Button>
            </DialogActions>
        </Dialog>
        );
}
