import * as React from "react";

import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 20,
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

export function PrivacyPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.appBarSpacer} />
            <h1>Our Privacy Policy</h1>
            <p>Last update: May 2018</p>
            <hr />
            <h3>Information we gather</h3>
            <p>
                The following information is gathered from our website visitors:
                <br />IP address
                <br />Anonymous information about how visiters use the site, so that we can make improvements.
            </p>
            <p>
                Please note that we make use of partner services which also gather information about visitors.  For more information please see the partner services section.
            </p>
            <h3>Cookies</h3>
            <p>Like most sites we use cookies to provide a personalized experience and improve the site.  These cookies will never contain any sensitive information.</p>
            <h3>How we use the information we gather</h3>
            <p>We use the information we collect as well as those that our partners collect on our behalf to track usage of the site, and improve the user experience.</p>
            <p>We do not use cookies to serve personalized ads to users in the European Economic Area.</p>
            <h3>The people that are given access to this information</h3>
            <p>The information we collect will be accessable by the owners of the site, and any people or organizations in accordance with the privacy policies of our partners.</p>
            <h3>Our partner services</h3>
            <p>
                Our website makes use of Google, as a third party vendor, to serve advertisements and track site usage.  Please refer to the <a href="http://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">Google Partner Privacy Policy</a> for
                information about the data Google collects and how they use it.
                <br />Our website makes use of share features provided by Facebook.  Please refer the <a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">Facebook privacy policy</a> for information about the data Facebook collects and how they use it.
            </p>
        </div>
    );
}
