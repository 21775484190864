/**
 * The state definition for the step settings modal.
 */
export interface IStepSettingsState {
    /**
     * A value indicating whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * The index of the step being edited.
     */
    editStepIndex: number | null;

    /**
     * The currently entered title text.
     */
    enteredTitle: string

    /**
     * The currently selected source type to indicate the source of input data.
     */
    selectedSourceType: string | null;

    /**
     * The currently selected source step to indicate the source of input data.
     */
    selectedSourceStep: number | null;

    /**
     * The currently selected transform type the indicate the desired transform method.
     */
    selectedInputDataType: string | null;

    /**
     * The currently selected transform type the indicate the desired transform method.
     */
    selectedTransformType: string | null;
}

/**
 * The initial state for the add step modal.
 */
export const InitialState: IStepSettingsState = {
    visible: false,
    editStepIndex: null,
    enteredTitle: "",
    selectedSourceStep: null,
    selectedSourceType: null,
    selectedInputDataType: null,
    selectedTransformType: null,
}