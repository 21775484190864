import { EmptyActions, IActionClass } from "./Actions";

import { Action } from "redux";
import { IReducer } from "./IReducer";
import { clone } from "lodash";

/**
 * The type for a list of action handlers based on a set of actions.
 */
export type IActionHandlers<TState, TActionMap extends IActionClass> = {
    [P in keyof TActionMap]: (clonedState: TState, ...params: Parameters<TActionMap[P]>) => void;
}

/**
 * Creates a reducer class from a given set of action handlers.
 */
export function createReducerClass<TState, TActionMap extends IActionClass>(actionHandlers: IActionHandlers<TState, TActionMap>): new (namespace: string) => IReducer<TState> {
    return class Reducer implements IReducer<TState> {
        public constructor(private namespace: string) {
        }
    
        public reduce(state: TState | undefined, action: Action<keyof TActionMap>): TState {
            if (!(action.type as string).startsWith(this.namespace)) {
                return state || {} as TState;
            }

            const clonedState = clone(state || {} as TState);

            //const keys = typedKeys(actionHandlers);

            for (const [key, value] of Object.entries(actionHandlers)) {
                const typedKey = key as keyof IActionHandlers<TState, TActionMap>;
                const typedValue = value as IActionHandlers<TState, TActionMap>[typeof typedKey];

                if (action.type === this.namespace + "." + typedKey) {
                    typedValue(clonedState, ...(action as any).payload);
                }
            }
    
            return clonedState;
        }
    };
}

export const EmptyReducer = createReducerClass<{}, EmptyActions>({});