import * as React from "react";

import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 20,
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

export function HelpPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.appBarSpacer} />
            <h1>Help</h1>

            <hr />

            <h2>Overview</h2>

            <p>This website can be used to quickly do queries on JSON or XML data and extract subsets of JSON or XML using a variety of query tools.</p>

            <h2>Usage</h2>

            <p>
                First enter your json/xml into the Input window, then enter your query into the Query window.
                You can select different query types using the dropdown in the workflow pane on the left.
                The query will automatically run, and if successful the results will be shown in the Output window.
                If the query did not run successfully, the Results window will be highlighted with a red border and
                the error will be shown in the Output window.
            </p>

            <h2>Steps</h2>

            <p>
                You can add multiple steps where the output of one step becomes the input of another step. Just click the Add Step button add a step.
                You can change the input type, name and transform type for a step by clicking on the gear icon in the step header.
                You can delete a step using the trashcan icon in the step header. It is not possible to delete a step that is being used as input for another step.
            </p>

            <h2>Formatting JSON/XML</h2>

            <p>You can format the json/xml entered into the Input window, by clicking on the format icon next to the input description in the workflow pane on the left.</p>

            <h2>Query help</h2>

            <p>To get more information about how to write a query using the selected query language, click the help button next to the query type dropdown in the workflow pane on the left.</p>

            <h2>Troubleshooting</h2>

            <p>
                Remember that single quotes are not valid delimiters for the start and end of strings in JSON.  See <a href="http://www.json.org">www.json.org</a> for more information.
            </p>
            <p>There is a red border around the Results editor window: This means that either your JSON is invalid or your query is invalid.</p>
        </div>
    );
}
