import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert'
import { IStepSettingsProps } from './IStepSettingsProps';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formSpacing: {
            marginBottom: 30,
            padding: 5
        },
    })
);

/**
 * Renders the step settings modal.
 */
export default function StepSettings(props: IStepSettingsProps) {
    const classes = useStyles();

    const sourceTypesRadioButtons = props.sourceTypes.map((item) => {
        return (
            <Tooltip key={item.key} title={item.tooltipText}>
                <FormControlLabel
                    value={item.key}
                    control={<Radio color="primary" />}
                    label={item.description}
                />
            </Tooltip>);
    });

    const sourceStepsMenuItems = props.sourceSteps.map((item, index) => {
        return (<MenuItem key={index} value={index}>{item}</MenuItem>);
    });

    const inputDataTypeMenuItems = props.inputDataTypes.map((item) => {
        return (<MenuItem key={item.key} value={item.key}>{item.description}</MenuItem>);
    });

    const transformMenuItems = props.transformTypes.map((item) => {
        return (<MenuItem key={item.key} value={item.key}>{item.description}</MenuItem>);
    });

    let inputTypeSpecificSettings = <></>;
    if (props.sourceStepsEnabled) {
        inputTypeSpecificSettings = (
            <FormControl>
                <InputLabel id="data-source-step-label">Input source step</InputLabel>
                <Select
                    labelId="data-source-step-label"
                    id="data-source-step-select"
                    value={props.selectedSourceStep}
                    onChange={(event) => { props.onSelectedSourceStepChanged(event.target.value as number); }}
                >
                    {sourceStepsMenuItems}
                </Select>
                <FormHelperText>The new step will use the output from this step as input.</FormHelperText>
            </FormControl>);
    }
    if (props.inputDataTypeEnabled) {
        inputTypeSpecificSettings = (
            <FormControl>
                <InputLabel id="input-data-type-step-label">Input data type</InputLabel>
                <Select
                    labelId="input-data-type-step-label"
                    id="input-data-type-step-select"
                    value={props.selectedInputDataType}
                    onChange={(event) => { props.onSelectedInputDataTypeChanged(event.target.value as string); }}
                >
                    {inputDataTypeMenuItems}
                </Select>
                <FormHelperText>The new step will require this type of data as input.</FormHelperText>
            </FormControl>);
    }

    let mismatchError = <></>;
    if (props.hasInputAndTransformTypeMismatch) {
        mismatchError = (
            <Alert variant="filled" severity="error">
                Input and transform type mismatch!
            </Alert>)
    }

    return (
        <div>
        <Dialog open={props.visible} onClose={() => props.onCancelClicked()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit step settings</DialogTitle>
            <DialogContent>
                <div className={classes.formSpacing}>
                    <TextField required id="step-name" label="Step name" value={props.enteredTitle} onChange={(event) => { props.onEnteredTitleChanged(event.target.value as string); }}/>
                </div>
                <div className={classes.formSpacing}>
                    <FormControl>
                        <FormLabel component="legend">Input type</FormLabel>
                        <RadioGroup row aria-label="source type" name="source type" defaultValue="manual" value={props.selectedSourceType} onChange={(event) => { props.onSelectedSourceTypeChanged(event.target.value as string); }}>
                            {sourceTypesRadioButtons}
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.formSpacing}>
                    {inputTypeSpecificSettings}
                </div>
                <div className={classes.formSpacing}>
                    <FormControl>
                        <InputLabel id="transform-type-label">Transform type</InputLabel>
                        <Select
                            labelId="transform-type-label"
                            id="transform-type-select"
                            value={props.selectedTransform}
                            onChange={(event) => { props.onSelectedTransformTypeChanged(event.target.value as string); }}
                        >
                            {transformMenuItems}
                        </Select>
                        <FormHelperText>The type of transformation language to use.</FormHelperText>
                    </FormControl>
                </div>
                {mismatchError}
            </DialogContent>
            <DialogActions>
                <Button disabled={!props.saveEnabled} onClick={() => props.onSaveClicked()} color="primary">
                    Save
                </Button>
                <Button onClick={() => props.onCancelClicked()} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
