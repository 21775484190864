import { IActionHandlers, createReducerClass } from "../../infrastructure";

import { IQueryExecutionState } from "./IQueryExecutionState";
import { QueryExecutionActions } from "./QueryExecutionActions";
import { clone } from "lodash";

const QueryExecutionActionHandlers: IActionHandlers<IQueryExecutionState, QueryExecutionActions> = {
    setActiveStepIndex: (clonedState, index) => {
        clonedState.activeStepIndex = index;
    },
    setActiveInputCode: (clonedState, code) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[clonedState.activeStepIndex].input = clone(clonedState.steps[clonedState.activeStepIndex].input);
        clonedState.steps[clonedState.activeStepIndex].input.data = code;
    },
    setActiveTransformCode: (clonedState, code) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[clonedState.activeStepIndex].transform = clone(clonedState.steps[clonedState.activeStepIndex].transform);
        clonedState.steps[clonedState.activeStepIndex].transform.code = code;
    },
    setActiveOutput: (clonedState, result, hasError) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[clonedState.activeStepIndex].output = clone(clonedState.steps[clonedState.activeStepIndex].output);
        clonedState.steps[clonedState.activeStepIndex].output.result = result;
        clonedState.steps[clonedState.activeStepIndex].output.hasError = hasError;
    },

    setOutput: (clonedState, stepIndex, result, hasError) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[stepIndex].output = clone(clonedState.steps[stepIndex].output);
        clonedState.steps[stepIndex].output.result = result;
        clonedState.steps[stepIndex].output.hasError = hasError;
    },
    setTransformType: (clonedState, stepIndex, transformType) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[stepIndex].transform = clone(clonedState.steps[stepIndex].transform);
        clonedState.steps[stepIndex].transform.transformType = transformType;
    },
    setTransformCode: (clonedState, stepIndex, code) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps[stepIndex].transform = clone(clonedState.steps[stepIndex].transform);
        clonedState.steps[stepIndex].transform.code = code;
    },

    addStep: (clonedState, title, sourceType, sourceDataType, sourceStepIndex, transformType, transformCode) => {
        clonedState.steps = clone(clonedState.steps);

        let data = "{}"

        clonedState.activeStepIndex = clonedState.steps.length;
        
        clonedState.steps.push({
            title: title,
            input: {
                sourceType: sourceType,
                data: data,
                dataType: sourceDataType,
                sourceStepIndex: sourceStepIndex,
                sourceLoading: false,
                sourceLoadingError: null
            },
            transform: {
                transformType: transformType,
                code: transformCode
            },
            output: {
                result: "",
                hasError: false
            }
        });
    },
    updateStep: (clonedState, index, title, sourceType, sourceDataType, sourceStepIndex, transformType, transformCode) => {
        clonedState.steps = clone(clonedState.steps);

        clonedState.steps[index].title = title;

        clonedState.steps[index].input = clone(clonedState.steps[index].input);
        clonedState.steps[index].input.sourceType = sourceType;
        clonedState.steps[index].input.dataType = sourceDataType;
        clonedState.steps[index].input.sourceStepIndex = sourceStepIndex;

        clonedState.steps[index].transform = clone(clonedState.steps[index].transform);
        clonedState.steps[index].transform.transformType = transformType;
        clonedState.steps[index].transform.code = transformCode;
    },
    deleteStep: (clonedState, index) => {
        clonedState.steps = clone(clonedState.steps);
        clonedState.steps.splice(index, 1);
        if (clonedState.activeStepIndex >= index) {
            clonedState.activeStepIndex--;
        }
    },
}

export const QueryExecutionReducer = createReducerClass(QueryExecutionActionHandlers);