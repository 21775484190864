export class AppRouting {
    public static readonly helpSegment = "help";
    public static readonly privacySegment = "privacy";
    public static readonly creditsSegment = "credits";
    public static readonly sampleSegment = "sample";
    public static readonly homePath = "/";
    public static readonly helpPath = AppRouting.homePath + AppRouting.helpSegment;
    public static readonly privacyPath = AppRouting.homePath + AppRouting.privacySegment;
    public static readonly creditsPath = AppRouting.homePath + AppRouting.creditsSegment;
    public static readonly samplePath = AppRouting.homePath + AppRouting.sampleSegment + "/:id";

    public static readonly homeTitle = "Home";
    public static readonly privacyTitle = "Privacy";
    public static readonly helpTitle = "Help";
    public static readonly creditsTitle = "Credits";
}