import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class LodashQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "_.chain(input)\n    .result('array')\n    .filter({ key: 2 })\n    .first()\n    .result('dictionary')\n    .result('d')\n    .value();";
    }

    public name: QueryType = QueryType.Lodash;

    public displayName: string = "Lodash (4.17.15)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Json;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "_.chain(input)\n    .value();";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://lodash.com";

    public editorMode: string = "javascript";
}
