import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class JSONPathQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "$.array[?(@.key==2)].dictionary.c";
    }

    public name: QueryType = QueryType.JSONPath;

    public displayName: string = "JSONPath (Goessner 0.8.0)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Json;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "*";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://goessner.net/articles/jsonpath";

    public editorMode: string = "text";
}
