import { IActionHandlers, createReducerClass } from "../../infrastructure";

import { ITransformSettingsState } from "./ITransformSettingsState";
import { TransformSettingsActions } from "./TransformSettingsActions";

const TransformSettingsActionHandlers: IActionHandlers<ITransformSettingsState, TransformSettingsActions> = {
    setVisibility: (clonedState, visible) => {
        clonedState.visible = visible;
    },
    setSelectedTransformType: (clonedState, selectedTransformType) => {
        clonedState.selectedTransformType = selectedTransformType;
    },
    setStepIndex: (clonedState, stepIndex) => {
        clonedState.stepIndex = stepIndex;
    }
}

/**
 * Reducer to handle state updates for the transform settings modal.
 */
export const TransformSettingsReducer = createReducerClass(TransformSettingsActionHandlers);