import { IDataTypeInfo } from "./IDataTypeInfo";
import { JSONDataTypeInfo } from "./JSONDataTypeInfo";
import { XmlDataTypeInfo } from "./XmlDataTypeInfo";

export class DataTypeProvider {
    public readonly dataTypeInfoArray: IDataTypeInfo[] = [];
    public readonly dataTypeInfoDictionary: { [name: string]: IDataTypeInfo } = {};

    constructor() {
        this.dataTypeInfoArray.push(new JSONDataTypeInfo());
        this.dataTypeInfoArray.push(new XmlDataTypeInfo());

        for (var i = 0; i < this.dataTypeInfoArray.length; i++) {
            this.dataTypeInfoDictionary[this.dataTypeInfoArray[i].dataType] = this.dataTypeInfoArray[i];
        }
    }
}

export const DataTypeProviderInstance = new DataTypeProvider();