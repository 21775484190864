import { Experience, OrUndefined, createMemoizedFunction } from "../../infrastructure";
import { IStepSettingsState, InitialState } from "../../state/stepSettings/IStepSettingsState";

import { DataType } from "../../businessLogic/dataTypes/DataType";
import { DataTypeProviderInstance } from "../../businessLogic/dataTypes/DataTypeProvider";
import { Dispatch } from "redux";
import { IStepSettingsProps } from "../../components/stepSettings/IStepSettingsProps";
import { IStepState } from "../../state/queryExecution/IStepState";
import { QueryExecutionInspector } from "../../state/queryExecution/QueryExecutionInspector";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";
import { SourceTypeProviderInstance } from "../../businessLogic/sourceTypes/SourceTypeProvider";
import { StepSettingsActions } from "../../state/stepSettings/StepSettingsActions";
import { StepSettingsReducer } from "../../state/stepSettings/StepSettingsReducer";

type Settings = {
    actionMap: StepSettingsActions,
    children: {},
    parentCalls: {
        getStepState: (index: number) => IStepState,
        getStepsState: () => IStepState[],
        getExistingStepNames: () => string[],
        onSaveStep: (index: number, title: string, selectedSourceType: SourceType, selectedSourceStep: number, selectedInputDataType: string, selectedTransformType: string) => void
    }
};

/**
 * Contains the UI logic to control the step settings modal.
 */
export class StepSettingsExperience extends Experience<IStepSettingsState, IStepSettingsProps, Settings> {
    /**
     * Initializes a new instance of the `StepSettingsExperience` class.
     * @param namespace A namespace to differentiate this experience from siblings.
     */
    constructor(
        namespace: string) {
        super(namespace, StepSettingsActions, StepSettingsReducer, InitialState, [], []);

        this.buildSourceTypes = createMemoizedFunction(this.buildSourceTypes);
        this.buildInputDataTypes = createMemoizedFunction(this.buildInputDataTypes);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapStateToProps(state: IStepSettingsState, ownProps: {}): OrUndefined<IStepSettingsProps> {
        // Set the step index for the modal to 0 if it's not visible, to avoid an issue where
        // a step was being edited earlier, that is now deleted.
        let editStepIndex = state.editStepIndex;
        if (!state.visible) {
            editStepIndex = 0;
        }

        let transformTypeInfo = QueryInfoProviderInstance.queryInfoDictionary[state.selectedTransformType || QueryInfoProviderInstance.queryInfoArray[0].name];
        let sourceTypes = this.buildSourceTypes();
        if (editStepIndex === 0) {
            const manualEntry = SourceTypeProviderInstance.sourceTypeInfoDictionary[SourceType.manualEntry];
            sourceTypes = [{ key: manualEntry.sourceType, description: manualEntry.displayName, tooltipText: manualEntry.tooltipText }];
        }
        let inputDataType = state.selectedSourceType === SourceType.manualEntry ?
            state.selectedInputDataType as DataType || DataType.Json :
            QueryExecutionInspector.getStepInputDataInfo(editStepIndex || 0, this.parentCalls.getStepsState()).dataType;
        return {
            visible: state.visible,
            enteredTitle: state.enteredTitle,
            sourceStepsEnabled: state.selectedSourceType === SourceType.previousStepOutput,
            inputDataTypeEnabled: state.selectedSourceType === SourceType.manualEntry,
            sourceTypes: sourceTypes,
            sourceSteps: state.selectedSourceType === SourceType.previousStepOutput ? this.parentCalls.getExistingStepNames().slice(0, editStepIndex || 0) : [""],
            inputDataTypes: this.buildInputDataTypes(),
            transformTypes: QueryInfoProviderInstance.buildFilteredTransformTypes(
                inputDataType,
                transformTypeInfo.name),
            selectedSourceType: state.selectedSourceType || "",
            selectedSourceStep: state.selectedSourceType === SourceType.previousStepOutput ? state.selectedSourceStep || 0 : 0,
            selectedInputDataType: state.selectedInputDataType || "",
            selectedTransform: state.selectedTransformType || "",
            hasInputAndTransformTypeMismatch: transformTypeInfo.inputDataType !== inputDataType,
            saveEnabled: transformTypeInfo.inputDataType === inputDataType,
            onEnteredTitleChanged: undefined,
            onSelectedSourceTypeChanged: undefined,
            onSelectedSourceStepChanged: undefined,
            onSelectedInputDataTypeChanged: undefined,
            onSelectedTransformTypeChanged: undefined,
            onCancelClicked: undefined,
            onSaveClicked: undefined,
        };
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapDispatchToProps(dispatch: Dispatch, ownProps: {}): OrUndefined<IStepSettingsProps> {
        return {
            visible: undefined,
            sourceStepsEnabled: undefined,
            inputDataTypeEnabled: undefined,
            enteredTitle: undefined,
            sourceTypes: undefined,
            sourceSteps: undefined,
            inputDataTypes: undefined,
            transformTypes: undefined,
            selectedSourceType: undefined,
            selectedSourceStep: undefined,
            selectedInputDataType: undefined,
            selectedTransform: undefined,
            hasInputAndTransformTypeMismatch: undefined,
            saveEnabled: undefined,
            onEnteredTitleChanged: this.actions.setEnteredTitle,
            onSelectedSourceTypeChanged: this.actions.setSelectedSourceType,
            onSelectedSourceStepChanged: this.actions.setSelectedSourceStep,
            onSelectedInputDataTypeChanged: this.actions.setSelectedInputDataType,
            onSelectedTransformTypeChanged: this.actions.setSelectedTransformType,
            onCancelClicked: this.onCancelClicked,
            onSaveClicked: this.onSaveClicked,
        };
    }

    /**
     * Make the modal visible and setup fields.
     */
    public showModal(index: number) {
        const stepState = this.parentCalls.getStepState(index);
        this.actions.setupModal(
            index,
            stepState.title,
            stepState.input.sourceType,
            stepState.input.sourceStepIndex,
            stepState.input.dataType,
            stepState.transform.transformType);
    }

    /**
     * Build an array of source types from the list of registered source type providers.
     */
    private buildSourceTypes(): { key: string, description: string, tooltipText: string }[] {
        return SourceTypeProviderInstance.sourceTypeInfoArray.map((info) => {
            return { key: info.sourceType, description: info.displayName, tooltipText: info.tooltipText };
        });
    }

    /**
     * Build an array of input data types from the list of registered data type providers.
     */
    private buildInputDataTypes(): { key: string, description: string }[] {
        return DataTypeProviderInstance.dataTypeInfoArray.map((info) => {
            return { key: info.dataType, description: info.displayName };
        });
    }

    /**
     * Event handler for save button click.
     */
    private onSaveClicked(): void {
        const state = this.getState();
        this.parentCalls.onSaveStep(
            state.editStepIndex as number,
            state.enteredTitle,
            state.selectedSourceType as SourceType,
            state.selectedSourceStep as number,
            state.selectedInputDataType as string,
            state.selectedTransformType as string
        );
        this.actions.setVisibility(false);
    }

    /**
     * Event handler for cancel button click.
     */
    private onCancelClicked(): void {
        this.actions.setVisibility(false);
    }
}