import { Container, Drawer, Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Ad } from '../ad/Ad';
import { IQueryPageProps } from './IQueryPageProps';

const MonacoEditor = React.lazy(() => import("react-monaco-editor"));

const drawerWidth = 340;
const horizontalCodeWindowHeight = 380;
const verticalCodeWindowHeight = 780;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        appBarSpacer: theme.mixins.toolbar,
        container: {
            paddingLeft: 4,
            paddingRight: 4,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        cardTitle: {
            margin: 0,
        },
        paper: {
            flex: 1,
            color: theme.palette.text.secondary,
        },
        paperLeftRedOutline: {
            boxShadow: "0px 3px 3px -2px rgba(255,0,0,0.2), 0px 3px 4px 0px rgba(255,0,0,0.14), 0px 1px 8px 0px rgba(255,0,0,1)",
            color: theme.palette.text.secondary,
        },
        gridItem: {
            display: "flex",
            flexDirection: "column",
        },
    }),
);

/**
 * Renders the query page.
 * @param props The component properties.
 */
function QueryPage(props: IQueryPageProps) {
    const classes = useStyles();

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    });

    const advert = props.adEnabled ? (
        <>
            <h2 className={classes.cardTitle}>Advertisement</h2>
            <Paper className={classes.paper} elevation={0}>
                <Ad/>
            </Paper>
        </>) : (
        <>
            <h2 className={classes.cardTitle}>Advertisement</h2>
            <Paper className={classes.paper} elevation={0}>
            </Paper>
        </>);
    
    let verticalLayoutAdvert = (<></>);
    if (!props.horizontalLayout) {
        verticalLayoutAdvert = advert;
    }
    
    let horizontalLayoutAdvertGrid = (<></>);
    if (props.horizontalLayout) {
        horizontalLayoutAdvertGrid = (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {advert}
            </Grid>);
    }

    let largeSizeGridWidth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 = 4;
    if (props.horizontalLayout) {
        largeSizeGridWidth = 6;
    }

    let inputLargeSizeGridWith: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12  = largeSizeGridWidth;
    if (props.horizontalLayout && !props.hasTransform) {
        inputLargeSizeGridWith = 12;
    }


    let codeWindowHeight = verticalCodeWindowHeight;
    if (props.horizontalLayout) {
        codeWindowHeight = horizontalCodeWindowHeight;
    }

    let transformHeightDifference = 0.5;
    if (props.horizontalLayout) {
        transformHeightDifference = 1;
    }

    // Render the query editor or a placeholder if there is no query required.
    let transform = <></>;

    // If we are in the vertical layout we always need a grid item for the ad whether we have a query or not.
    if (!props.horizontalLayout) {
        transform = (
            <Grid className={classes.gridItem} item xl={largeSizeGridWidth} lg={largeSizeGridWidth} md={largeSizeGridWidth} sm={12} xs={12}>
                {verticalLayoutAdvert}
            </Grid>);
    }

    // If we have a query we need to render both the query and the ad (for the vertical view);
    if (props.hasTransform) {
        transform = (
            <Grid className={classes.gridItem} item xl={largeSizeGridWidth} lg={largeSizeGridWidth} md={largeSizeGridWidth} sm={12} xs={12}>
                <h2 className={classes.cardTitle}>Query</h2>
                <Paper className={classes.paper} elevation={3}>
                    <React.Suspense fallback={<div style={{height: codeWindowHeight}}></div>}>
                        <MonacoEditor
                            key="transform"
                            width="100%"
                            height={codeWindowHeight * transformHeightDifference}
                            language={props.transformEditorType}
                            theme="vs-dark"
                            value={props.transformText}
                            options={{
                            selectOnLineNumbers: true,
                            automaticLayout: true
                            }}
                            onChange={(newValue, e) => props.onTransformTextChanged(newValue)}
                        />
                    </React.Suspense>
                </Paper>
                {verticalLayoutAdvert}
            </Grid>);
    }

    return (
        <div className={classes.root}>
            {React.createElement(props.transformSettingsComponent)}
            {React.createElement(props.addStepComponent)}
            <Drawer
                hidden={!props.drawerOpen}
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}>
                <div className={classes.appBarSpacer} />
                <div className={classes.drawerContainer}>
                    {React.createElement(props.workflowComponent)}
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid className={classes.gridItem} item xl={inputLargeSizeGridWith} lg={inputLargeSizeGridWith} md={inputLargeSizeGridWith} sm={12} xs={12}>
                            <h2 className={classes.cardTitle}>Input</h2>
                            <Paper className={classes.paper} elevation={3}>
                                <React.Suspense fallback={<div style={{height: codeWindowHeight}}></div>}>
                                    <MonacoEditor
                                        key="input"
                                        width="100%"
                                        height={codeWindowHeight}
                                        language={props.inputEditorType}
                                        theme="vs-dark"
                                        value={props.inputText}
                                        options={{
                                        selectOnLineNumbers: true,
                                        readOnly: !props.inputEditorEnabled,
                                        automaticLayout: true
                                        }}
                                        onChange={(newValue, e) => props.onInputTextChanged(newValue)}
                                    />
                                </React.Suspense>
                            </Paper>
                        </Grid>
                        {transform}
                        <Grid className={classes.gridItem} item xl={largeSizeGridWidth} lg={largeSizeGridWidth} md={largeSizeGridWidth} sm={12} xs={12}>
                            <h2 className={classes.cardTitle}>Output</h2>
                            <Paper className={props.outputInError ? classes.paperLeftRedOutline : classes.paper} elevation={3}>
                                <React.Suspense fallback={<div style={{height: codeWindowHeight}}></div>}>
                                    <MonacoEditor
                                        key="transform"
                                        width="100%"
                                        height={codeWindowHeight}
                                        language={props.outputEditorType}
                                        theme="vs-dark"
                                        value={props.outputText}
                                        options={{
                                            selectOnLineNumbers: true,
                                            readOnly: true,
                                            automaticLayout: true
                                        }}
                                    />
                                </React.Suspense>
                            </Paper>
                        </Grid>
                        {horizontalLayoutAdvertGrid}
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

export default QueryPage;
