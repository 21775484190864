/**
 * The types of query that are supported.
 */
export enum QueryType {
    JSONPathDChester = "JSONPathDChester",
    JSONPathPlus = "JSONPathPlus",
    JSONPath = "JSONPath",
    JSONata = "JSONata",
    JSONPointer = "JSONPointer",
    JSPath = "JSPath",
    JPath = "JPath",
    Lodash = "Lodash",
    Underscore = "Underscore",
    JavaScript = "JavaScript",
    xml_js_toxml = "xml_js_toxml",
    xml2js_toxml = "xml2js_toxml",
    xml2js_tojson = "xml2js_tojson",
    SimpleXPath = "SimpleXPath",
    SimpleXSLT = "SimpleXSLT"
}