import { DataType } from "../../businessLogic/dataTypes/DataType";
import { IStepState } from "./IStepState";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";

/**
 * The state type for the query execution functionality in the app.
 */
export interface IQueryExecutionState {
    // Steps.
    steps: IStepState[];

    // Active steps.
    activeStepIndex: number;
}

export const InitialState: IQueryExecutionState = {
    steps: [{
        title: "Step 1",
        input: {
            dataType: DataType.Json,
            data: JSON.stringify({
                "key" : "value",
                "array" : [
                    { "key" : 1 },
                    { "key" : 2, "dictionary": {
                            "a": "Apple",
                            "b": "Butterfly",
                            "c": "Cat",
                            "d": "Dog"
                        } },
                    { "key" : 3 }
                ]
            }, null, 4),
            sourceType: SourceType.manualEntry,
            sourceStepIndex: null,
            sourceLoading: false,
            sourceLoadingError: null
        },
        transform: {
            transformType: "JSONPathDChester",
            code: QueryInfoProviderInstance.queryInfoDictionary["JSONPathDChester"].getSampleQuery()
        },
        output: {
            result: "",
            hasError: false
        }
    }],
    activeStepIndex: 0
}