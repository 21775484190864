import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class JSONataQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "array[key=2].dictionary.d";
    }

    public name: QueryType = QueryType.JSONata;

    public displayName: string = "JSONata (1.8.3)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Json;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "$";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://www.npmjs.com/package/jsonata";

    public editorMode: string = "text";
}
