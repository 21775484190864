import { ChildrenWithComponents, Experience, OrUndefined, createMemoizedFunction } from "../../infrastructure";
import { IQueryExecutionState, InitialState } from "../../state/queryExecution/IQueryExecutionState";

import { DataType } from "../../businessLogic/dataTypes/DataType";
import { DataTypeProviderInstance } from "../../businessLogic/dataTypes/DataTypeProvider";
import { Dispatch } from "redux";
import { IQueryPageProps } from "../../components/queryPage/IQueryPageProps";
import { IStepState } from "../../state/queryExecution/IStepState";
import { QueryExecutionActions } from "../../state/queryExecution/QueryExecutionActions";
import { QueryExecutionInspector } from "../../state/queryExecution/QueryExecutionInspector";
import { QueryExecutionReducer } from "../../state/queryExecution/QueryExecutionReducer";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { SourceType } from "../../businessLogic/sourceTypes/SourceType";
import { StepSettingsExperience } from "../stepSettings/StepSettingsExperience";
import { TransformSettingsExperience } from "../transformSettings/TransformSettingsExperience";
import { WorkflowExperience } from "../workflow/WorkflowExperience";
import { sampleData } from "../../businessLogic/queryTypes/sampleData";

type Settings = {
    actionMap: QueryExecutionActions,
    children: {
        transformSettings: TransformSettingsExperience,
        workflow: WorkflowExperience,
        stepSettings: StepSettingsExperience
    },
    parentCalls: {
        isDrawerOpen: () => boolean
        isHorizontalLayout: () => boolean
        isCookieAlertVisible: () => boolean
    }
};

/**
 * Contains the UI logic to control the query page.
 */
export class QueryPageExperience extends Experience<IQueryExecutionState, IQueryPageProps, Settings> {
    /** The id of the timeout used to wait before queries are executed after changing query text or input data. */
    private timeout: number | undefined = undefined;

    /** The time to wait after changing query text or input data before executing the query. */
    private queryExecuteWait = 500;

    /** A counter to generate unique new step names */
    private newStepCounter: number = 2;

    /**
     * Initializes a new instance of the `QueryPageExperience` class.
     * @param namespace A namespace to differentiate this experience from siblings.
     * @param children The child experiences of this experience.
     */
    constructor(
        namespace: string,
        children: ChildrenWithComponents<Settings>) {
        super(
            namespace,
            QueryExecutionActions,
            QueryExecutionReducer,
            InitialState,
            children,
            {
                transformSettings: {
                    onSelectedTransformTypeUpdated: (stepIndex: number, transformType: string) => {
                        const state = this.getState();
                        if (state.steps[stepIndex].transform.transformType !== transformType) {
                            this.actions.setTransformType(stepIndex, transformType);
                            this.actions.setTransformCode(stepIndex, QueryInfoProviderInstance.queryInfoDictionary[transformType].getSampleQuery());
                            this.executeQuery();
                        }
                    }
                },
                workflow: {
                    getStepsState: () => {
                        return this.getState().steps;
                    },
                    getCurrentStepIndex: () => {
                        return this.getState().activeStepIndex;
                    },
                    onAddStepClicked: () => {
                        const currentsteps = this.getState().steps;
                        const currentStepsCount = currentsteps.length;

                        // Get the first query type from the list of query types filtered for the input data type for the new step.
                        const defaultQueryType = QueryInfoProviderInstance.buildFilteredTransformTypes(
                            QueryExecutionInspector.getStepOutputDataInfo(currentStepsCount - 1, currentsteps).dataType,
                            null)[0].key;
                        const defaultQueryTypeInfo = QueryInfoProviderInstance.queryInfoDictionary[defaultQueryType];

                        this.actions.addStep(
                            "New Step " + this.newStepCounter++,
                            SourceType.previousStepOutput,
                            DataType.Json,
                            currentStepsCount - 1,
                            defaultQueryTypeInfo.name,
                            defaultQueryTypeInfo.getSelectAllQuery()
                        )
                        this.delayExecuteQuery();
                    },
                    onCurrentStepChanged: (index: number) => {
                        this.actions.setActiveStepIndex(index);
                        this.executeAllQueries();
                    },
                    onDeleteStepClicked: (index: number) => {
                        this.actions.deleteStep(index);
                        this.executeAllQueries();
                    },
                    onInputFormatClicked: (index: number) => {
                        const step = this.getState().steps[index];
                        const data = step.input.data;
                        const inputType = QueryInfoProviderInstance.queryInfoDictionary[step.transform.transformType].inputDataType;
                        const formattedData = DataTypeProviderInstance.dataTypeInfoDictionary[inputType].format(data);
                        this.actions.setActiveInputCode(formattedData);
                        this.delayExecuteQuery();
                    },
                    onStepSettingsClicked: (index: number) => {
                        this.children.stepSettings.experience.showModal(index);
                    },
                    onTransformTypeChanged: (index: number, transformType: string) => {
                        const state = this.getState();
                        if (state.steps[index].transform.transformType !== transformType) {
                            this.actions.setTransformType(index, transformType);
                            this.actions.setTransformCode(index, index === 0 ?
                                QueryInfoProviderInstance.queryInfoDictionary[transformType].getSampleQuery() :
                                QueryInfoProviderInstance.queryInfoDictionary[transformType].getSelectAllQuery());
                            this.executeAllQueries();
                        }
                    }
                },
                stepSettings: {
                    getStepsState: () => {
                        return this.getState().steps;
                    },
                    getStepState: (index: number) => {
                        return this.getState().steps[index];
                    },
                    getExistingStepNames: () => {
                        return this.getStepNames(this.getState().steps);
                    },
                    onSaveStep: (index: number, title: string, selectedSourceType: SourceType, selectedSourceStep: number, selectedInputDataType: string, selectedTransformType: string) => {
                        const currentStep = this.getState().steps[index];
                        this.actions.updateStep(
                            index,
                            title,
                            selectedSourceType,
                            selectedInputDataType as DataType,
                            selectedSourceStep,
                            selectedTransformType,
                            currentStep.transform.transformType === selectedTransformType ?
                                currentStep.transform.code :
                                index === 0 ?
                                    QueryInfoProviderInstance.queryInfoDictionary[selectedTransformType].getSampleQuery() :
                                    QueryInfoProviderInstance.queryInfoDictionary[selectedTransformType].getSelectAllQuery());
                        this.executeAllQueries();
                    }
                }
            });
        
        this.getStepNames = createMemoizedFunction(this.getStepNames);
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapStateToProps(state: IQueryExecutionState, ownProps: {}): OrUndefined<IQueryPageProps> {
        const activeStep = state.steps[state.activeStepIndex];
        const transformInfo = QueryInfoProviderInstance.queryInfoDictionary[activeStep.transform.transformType];
        const outputDataTypeInfo = DataTypeProviderInstance.dataTypeInfoDictionary[transformInfo.outputDataType];
        let inputText = QueryExecutionInspector.getStepInput(state.activeStepIndex, state.steps);
        return {
            drawerOpen: this.parentCalls.isDrawerOpen(),
            horizontalLayout: this.parentCalls.isHorizontalLayout(),
            inputEditorEnabled: activeStep.input.sourceType === SourceType.manualEntry,
            inputEditorType: QueryExecutionInspector.getStepInputDataInfo(state.activeStepIndex, state.steps).editorType,
            inputText: inputText,
            hasTransform: transformInfo.hasQuery,
            transformEditorType: transformInfo.editorMode,
            transformText: activeStep.transform.code,
            outputEditorType: activeStep.output.hasError ? "text" : outputDataTypeInfo.editorType,
            outputText: activeStep.output.result,
            outputInError: activeStep.output.hasError,
            transformSettingsComponent: this.connectedChildren.transformSettings,
            workflowComponent: this.connectedChildren.workflow,
            addStepComponent: this.connectedChildren.stepSettings,
            adEnabled: !this.parentCalls.isCookieAlertVisible(),
            onInputTextChanged: undefined,
            onTransformTextChanged: undefined,
        };
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapDispatchToProps(dispatch: Dispatch, ownProps: {}): OrUndefined<IQueryPageProps> {
        return {
            drawerOpen: undefined,
            horizontalLayout: undefined,
            inputEditorEnabled: undefined,
            inputEditorType: undefined,
            inputText: undefined,
            hasTransform: undefined,
            transformEditorType: undefined,
            transformText: undefined,
            outputEditorType: undefined,
            outputText: undefined,
            outputInError: undefined,
            transformSettingsComponent: undefined,
            workflowComponent: undefined,
            addStepComponent: undefined,
            adEnabled: undefined,
            onInputTextChanged: (text: string) => {
                this.actions.setActiveInputCode(text);
                this.delayExecuteQuery();
            },
            onTransformTextChanged: (text: string) => {
                this.actions.setActiveTransformCode(text);
                this.delayExecuteQuery();
            }
        };
    }

    /**
     * Open a given sample.
     * @param sampleId The id of the given sample;
     */
    public openSamplePage(sampleId: string): void {
        const chosenData = sampleData[sampleId];
        this.actions.setTransformType(0, chosenData.queryType);
        this.actions.setActiveInputCode(JSON.stringify(chosenData.data, null, 4));
        this.actions.setActiveTransformCode(chosenData.query);
        this.delayExecuteQuery();
    }

    /**
     * Gets the names of the given steps.
     * @param steps The steps to get names for.
     */
    private getStepNames(steps: IStepState[]) {
        return steps.map(x => x.title);
    }

    /**
     * Execute the currently selected query after a configured delay,
     * unless this gets called before the execution, in which case
     * the delays is reset.
     */
    private delayExecuteQuery(): void {
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.executeQuery();
        }, this.queryExecuteWait);
    }

    /**
     * Execute all queries on the page.
     */
    private executeAllQueries(): void {
        const state = this.getState();
        import("../../businessLogic/queryTypes/QueryProvider").then(queryProviderImports => {
            for (let step = 0; step < state.steps.length; step++) {
                this.executeQueryWithImport(queryProviderImports, step);
            }
        });
    }

    /**
     * Execute the query with the given index or the currently selected if no index is provided.
     */
    private executeQuery(stepIndex?: number): void {
        import("../../businessLogic/queryTypes/QueryProvider").then(queryProviderImports => {
            this.executeQueryWithImport(queryProviderImports, stepIndex);
        });
    }

    /**
     * Execute the query with the given index or the currently selected if no index is provided.
     */
    private executeQueryWithImport(queryProviderImports: typeof import("../../businessLogic/queryTypes/QueryProvider"), stepIndex?: number): void {
        const state = this.getState();
        const nonNullStepIndex = stepIndex == null ? state.activeStepIndex : stepIndex;
        let inputText = QueryExecutionInspector.getStepInput(nonNullStepIndex, state.steps);
        const activeStep = state.steps[nonNullStepIndex];

        // Only send telemetry if cookie alert was dismissed.
        if (!this.parentCalls.isCookieAlertVisible())
        {
            ga("send", "event", "executeFunction", "start", activeStep.transform.transformType);
            gtag('event', 'executeFunction', {
                'action': 'start',
                'transformType': activeStep.transform.transformType
            });
        }

        try {
            const result = queryProviderImports.QueryProviderInstance.queryDictionary[activeStep.transform.transformType].runScript(inputText, activeStep.transform.code);
            if (result.success) {
                if (result.outputText === undefined) {
                    this.actions.setOutput(nonNullStepIndex, "undefined", false);
                } else if (result.outputText === null) {
                    this.actions.setOutput(nonNullStepIndex, "undefined", false);
                } else {
                    this.actions.setOutput(nonNullStepIndex, result.outputText, false);
                }
            } else {
                this.actions.setOutput(nonNullStepIndex, result.errorMessage || "", true);
            }

            // Only send telemetry if cookie alert was dismissed.
            if (!this.parentCalls.isCookieAlertVisible())
            {
                ga("send", "event", "executeFunction", "finishSuccess");
                gtag('event', 'executeFunction', {
                    'action': 'finishSuccess'
                });
            }
        }
        catch (exception) {
            this.actions.setOutput(nonNullStepIndex, exception, true);

            // Only send telemetry if cookie alert was dismissed.
            if (!this.parentCalls.isCookieAlertVisible())
            {
                ga("send", "event", "executeFunction", "finishFailure");
                gtag('event', 'executeFunction', {
                    'action': 'finishFailure'
                });
            }
        }
    }

    /**
     * Called at app startup, after store initialization, to do further setup.
     */
    public onStartup() {
        super.onStartup();
        this.timeout = window.setTimeout(() => {
            this.executeQuery();
        }, this.queryExecuteWait);
    }
}