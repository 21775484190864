/**
 * The state definition for the transform settings modal.
 */
export interface ITransformSettingsState {
    /**
     * A value indicating whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * The currently selected transform type in the transform type dropdown.
     */
    selectedTransformType: string | null;

    /**
     * The step index that is currently being edited.
     */
    stepIndex: number | null;
}

/**
 * The initial state for the transform settings modal.
 */
export const InitialState: ITransformSettingsState = {
    visible: false,
    selectedTransformType: null,
    stepIndex: null
}