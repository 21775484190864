import { CompoundReducer, CompoundReducerParam } from "./CompoundReducer";
import { ConnectedComponent, GetProps, Matching, Shared, connect } from "react-redux";
import { Dispatch, PreloadedState, Store, createStore } from "redux";
import { IActionSenders, createActions } from "./Actions";

import { ComponentType } from "react";
import { IReducer } from "./IReducer";

export interface IExperience<TState, TProps, TTypeParams extends TypeParams<TState, any>, TParentState = any, TOwnProps = {}> {
    /**
     * The methods to access data and make callbacks from/to the parent.
     */
    parentCalls: TTypeParams["parentCalls"];

    /**
     * Fake property to get propeties type.
     */
    propsType: TProps;

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    mapStateToProps(state: TState, ownProps: TOwnProps): OrUndefined<TProps>;

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    mapDispatchToProps(dispatch: Dispatch, ownProps: TOwnProps): OrUndefined<TProps>;

    /**
     * Connect the given component to the experience.
     * @param component The component to connect to the experience.
     */
    connectExperience<C extends ComponentType<Matching<TProps, GetProps<C>>>>(component: C): ConnectedComponent<C, Omit<GetProps<C>, keyof Shared<TProps, GetProps<C>>> & any>;

    buildInitialState(): TState;

    getReducer(): IReducer<TState>;

    /**
     * Get the current state from the store.
     */
    getState(): TState;

    /**
     * Get the current store.
     */
    getStore(): Store;

    /**
     * Get the local namespace from the store.
     */
    getNamespace(): string;

    /**
     * Set the parent experience of this experience.
     * @param parentExperience The parent experience of this experience.
     * @param stateSelector A function to get the state for this experience from the parent's state.
     */
    setParent(parentExperience: Experience<TParentState, any, any>, stateSelector: (parentState: TParentState) => TState): void;

    onStartup(): void;
}

type ChildrenDictionary<TState> = { [childName: string]: IExperience<any, any, any, TState> };
type ParentCalls = { [callName: string]: (...args: any[]) => any };

type TypeParams<TState, TActionMap> = {
    children: ChildrenDictionary<TState>
    actionMap: TActionMap;
    parentCalls: ParentCalls;
}

type ChildrenCalls<TTypeParams extends TypeParams<any, any>> = {
    [childname in keyof TTypeParams["children"]]: TTypeParams["children"][childname]["parentCalls"]
}

export type ChildrenWithComponents<TTypeParams extends TypeParams<any, any>> = {
    [childname in keyof TTypeParams["children"]]: {
        experience: TTypeParams["children"][childname],
        component: ComponentType<TTypeParams["children"][childname]["propsType"]> }
}

type ConnectedChildren<TTypeParams extends TypeParams<any, any>> = {
    [childname in keyof TTypeParams["children"]]: ConnectedComponent<any, TTypeParams["children"][childname]["propsType"]>
}

type CompoundState<TState, TTypeParams extends TypeParams<TState, any>> =
    TState & { [P in keyof TTypeParams["children"]]: Parameters<TTypeParams["children"][P]["mapStateToProps"]>[0] }

export type OrUndefined<T> = {
    [P in keyof T]: T[P] | undefined;
}

/* eslint-disable */
function assign<T>(target: T, ...sources: T[]): T {
  Object.assign(target as any, ...sources.map(x =>
    Object.entries(x)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => ((obj as any)[key] = value, obj), {})
  ));
  return target;
}
/* eslint-enable */

/**
 * Base class for experiences
 */
export abstract class Experience<TState, TProps, TTypeParams extends TypeParams<TState, any>, TParentState = any, TOwnProps = {}> implements IExperience<TState, TProps, TTypeParams, TParentState, TOwnProps> {
    /**
     * The redux store.
     */
    private store: Store | null = null;

    /**
     * The parent experience of this experience.
     */
    private parentExperience: Experience<TParentState, any, TypeParams<any, any>, any, any> | null = null;

    /**
     * A function to get the state for this experience from the parent's state.
     */
    private stateSelector: ((parentState: TParentState) => TState) | null = null;

    /**
     * Fake property to get propeties type.
     */
    public propsType: TProps = {} as any;

    /**
     * The action senders for the experience.
     */
    public actions: IActionSenders<TTypeParams["actionMap"]> = {} as any;

    /**
     * The methods to access data and make callbacks from/to the parent.
     */
    public parentCalls: TTypeParams["parentCalls"] = {} as any;

    /**
     * A dictionary of all the connected children.
     */
    public connectedChildren: ConnectedChildren<TTypeParams> = {} as any;

    constructor(
        private namespace: string,
        private actionMapClass: { new(): TTypeParams["actionMap"] },
        private reducerClass: { new(namespace: string): IReducer<TState> },
        private initialState: TState,
        protected children: ChildrenWithComponents<TTypeParams>,
        private childrenCalls: ChildrenCalls<TTypeParams>) {

        for (const [key, value] of Object.entries(this.children as ChildrenWithComponents<TTypeParams>)) {
            value.experience.setParent(this, (state: TState) => (state as any)[key]);
        }
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public abstract mapStateToProps(state: CompoundState<TState, TTypeParams>, ownProps: TOwnProps): OrUndefined<TProps>;

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public abstract mapDispatchToProps(dispatch: Dispatch, ownProps: TOwnProps): OrUndefined<TProps>;

    /**
     * Connect the given component to the experience.
     * @param component The component to connect to the experience.
     */
    public connectExperience<C extends ComponentType<Matching<TProps, GetProps<C>>>>(component: C): ConnectedComponent<C, Omit<GetProps<C>, keyof Shared<TProps, GetProps<C>>> & any> {
        return connect<TProps, TProps, TOwnProps, TProps>(
            (state: any, ownProps: TOwnProps) => {
                return this.mapStateToProps(this.getState(), ownProps) as TProps;
            },
            this.mapDispatchToProps.bind(this) as (dispatch: Dispatch, ownProps: TOwnProps) => TProps,
            (stateProps: TProps, dispatchProps: TProps, ownProps: TOwnProps) => {
                return assign(stateProps, dispatchProps);
            })(component);
    }

    public buildInitialState(): CompoundState<TState, TTypeParams> {
        const state: CompoundState<TState, TTypeParams> = {
            ...this.initialState,
        } as any;

        for (const [key, value] of Object.entries(this.children as ChildrenWithComponents<TTypeParams>)) {
            state[key as keyof TTypeParams["children"]] = value.experience.buildInitialState();
        }

        return state;
    }

    /**
     * Initialize the store.
     */
    public initializeStore(): void {
        const reducer = this.getReducer();
        this.store = createStore(reducer.reduce.bind(reducer), this.buildInitialState() as PreloadedState<CompoundState<TState, TTypeParams>>);
    }

    public getReducer(): IReducer<TState> {
        const reducers: CompoundReducerParam<TState, any>[] = [];
        reducers.push({ reducer: new this.reducerClass(this.getNamespace())});

        for (const [key, value] of Object.entries(this.children as ChildrenWithComponents<TTypeParams>)) {
            reducers.push({ reducer: value.experience.getReducer(), stateGetter: (state) => (state as any)[key], stateSetter: (parentState, state) => (parentState as any)[key] = state });
        }

        return new CompoundReducer(reducers);
    }

    /**
     * Get the current state from the store.
     */
    public getState(): CompoundState<TState, TTypeParams> {
        if (this.parentExperience && this.stateSelector) {
            return this.stateSelector(this.parentExperience.getState()) as CompoundState<TState, TTypeParams>;
        }

        if (this.store !== null) {
            return this.store.getState();
        }

        throw new Error("Call initializeStore or connect this experience to a parent before calling getState.");
    }

    /**
     * Get the current store.
     */
    public getStore(): Store {
        if (this.parentExperience) {
            return this.parentExperience.getStore();
        }

        if (this.store !== null) {
            return this.store;
        }

        throw new Error("Call initializeStore or connect this experience to a parent before calling getStore.");
    }

    /**
     * Get the local namespace from the store.
     */
    public getNamespace(): string {
        if (this.parentExperience) {
            return this.parentExperience.getNamespace() + "." + this.namespace;
        }

        return this.namespace;
    }

    /**
     * Set the parent experience of this experience.
     * @param parentExperience The parent experience of this experience.
     * @param stateSelector A function to get the state for this experience from the parent's state.
     */
    public setParent(parentExperience: Experience<TParentState, any, any>, stateSelector: (parentState: TParentState) => TState): void {
        this.parentExperience = parentExperience;
        this.stateSelector = stateSelector;
    }

    /**
     * Return callbacks for the given child experience.
     * @param childExperience The child experience to get callbacks for.
     */
    public getChildCalls(childExperience: IExperience<any, any, any>) {
        for (const [key, value] of Object.entries(this.children as ChildrenWithComponents<TTypeParams>)) {
            if (childExperience === value.experience) {
                return this.childrenCalls[key];
            }
        }

        throw new Error("Unknown child is trying to get child calls.");
    }

    public onStartup() {
        this.actions = createActions(this.getStore().dispatch, this.getNamespace(), new this.actionMapClass());

        for (const [key, value] of Object.entries(this.children as ChildrenWithComponents<TTypeParams>)) {
            value.experience.onStartup();
            this.connectedChildren[key as keyof TTypeParams["children"]] = value.experience.connectExperience(value.component);
        }

        if (this.parentExperience) {
            this.parentCalls = this.parentExperience.getChildCalls(this);
        }
    }
}