import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './components/app/AppRouter';
import { AppExperience } from './experiences/app/AppExperience';
import { Provider } from 'react-redux';
import QueryPage from './components/queryPage/QueryPage';
import { QueryPageExperience } from './experiences/queryPage/QueryPageExperience';
import React from 'react';
import ReactDOM from 'react-dom';
import StepSettings from './components/stepSettings/StepSettings';
import { StepSettingsExperience } from './experiences/stepSettings/StepSettingsExperience';
import TransformSettings from './components/transformSettings/TransformSettings';
import { TransformSettingsExperience } from './experiences/transformSettings/TransformSettingsExperience';
import Workflow from './components/workflow/Workflow';
import { WorkflowExperience } from './experiences/workflow/WorkflowExperience';

const appExperience = new AppExperience("root", {
    queryPage: { experience: new QueryPageExperience("queryPage", {
        transformSettings: { experience: new TransformSettingsExperience("transformSettings"), component: TransformSettings },
        workflow: { experience: new WorkflowExperience("workflow"), component: Workflow },
        stepSettings: { experience: new StepSettingsExperience("stepSettings"), component: StepSettings },
    }), component: QueryPage }
});
appExperience.initializeStore();
appExperience.onStartup();
const ConnectedApp = appExperience.connectExperience(App);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={appExperience.getStore()}>
            <ConnectedApp/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
