import { ChildrenWithComponents, Experience, OrUndefined } from "../../infrastructure";
import { IAppState, InitialState } from "../../state/app/IAppState";

import { AppActions } from "../../state/app/AppActions";
import { AppReducer } from "../../state/app/AppReducer";
import { Dispatch } from "redux";
import { IAppProps } from "../../components/app/IAppProps";
import { QueryPageExperience } from "../queryPage/QueryPageExperience";

type Settings = {
    actionMap: AppActions,
    children: {
        queryPage: QueryPageExperience
    },
    parentCalls: {}
};

/**
 * Contains the ui logic for managing the app.
 */
export class AppExperience extends Experience<IAppState, IAppProps, Settings> {
    private static cookieName: string = "cookiePopupDismissed";

    /**
     * Initializes a new instance of the `AppExperience` class.
     * @param namespace A namespace to differentiate this experience from siblings.
     * @param children The child experiences of this experience.
     */
    constructor(
        namespace: string,
        children: ChildrenWithComponents<Settings>) {

        // Check if the cookie modal has previously been dismissed and set it to visible if not.
        let showCookiePopup = false
        const cookieDismissed = AppExperience.getCookie(AppExperience.cookieName);
        if (cookieDismissed !== "true") {
            showCookiePopup = true;
        } else {
            // Since the cookie modal has been dismissed previously, we can setup analytics and collect page views.
            ga('send', 'pageview');
            gtag('js', new Date());
            gtag('config', 'G-XDV9QGVBR5');
        }

        super(
            namespace,
            AppActions,
            AppReducer,
            {
                ...InitialState,
                cookieAlertVisible: showCookiePopup,
                drawerOpen: window.document.documentElement.clientWidth < 961 ? false : true
            },
            children,
            {
                queryPage: {
                    isDrawerOpen: () => {
                        return this.getState().drawerOpen;
                    },
                    isHorizontalLayout: () => {
                        return this.getState().horizontalLayout;
                    },
                    isCookieAlertVisible: () => {
                        return this.getState().cookieAlertVisible;
                    }
                }
            });

        this.dismissPoup = this.dismissPoup.bind(this);
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapStateToProps(state: IAppState, ownProps: {}): OrUndefined<IAppProps> {
        return {
            queryPageComponent: this.connectedChildren.queryPage,
            cookieAlertVisible: state.cookieAlertVisible,
            horizontalLayout: state.horizontalLayout,
            onCookieAcceptClick: undefined,
            onSamplePageEnter: undefined,
            onDrawerToggle: undefined,
            onLayoutToggle: undefined
        };
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapDispatchToProps(dispatch: Dispatch, ownProps: {}): OrUndefined<IAppProps> {
        return {
            queryPageComponent: undefined,
            cookieAlertVisible: undefined,
            horizontalLayout: undefined,
            onCookieAcceptClick: this.dismissPoup,
            onSamplePageEnter: (sampleId: string) => {
                this.children.queryPage.experience.openSamplePage(sampleId);
            },
            onDrawerToggle: () => {
                this.actions.setDrawerOpen(!this.getState().drawerOpen);
            },
            onLayoutToggle: () => {
                this.actions.setHorizontalLayout(!this.getState().horizontalLayout);
            }
        };
    }

    /**
     * Event handler to dimiss the cookie modal and set a cookie to indicate that it has been dimissed.
     */
    public dismissPoup() {
        AppExperience.setCookie(AppExperience.cookieName, "true", 365);
        this.actions.setCookieState(false);
        ga("send", "event", "cookiePopup", "dismiss");
        gtag('event', 'cookiePopup', {
            'action': 'dismiss'
        });

        // Since the cookie modal has now been dismissed, we can now setup analysitcs and collect this page view.
        ga('send', 'pageview');
        gtag('js', new Date());
        gtag('config', 'G-XDV9QGVBR5');
    }

    /**
     * Set a cookie.
     * @param cname The cookie name.
     * @param cvalue The cookie value.
     * @param exdays The expiration in days from now.
     */
    private static setCookie(cname: string, cvalue: string, exdays: number) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    }

    /**
     * Get the contents of a cookie.
     * @param cname The cookie name.
     */
    private static getCookie(cname: string) {
        var name = cname + "=";
        var ca = window.document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }

        return "";
    }
}