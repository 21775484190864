import { ActionClass } from "../../infrastructure";

/**
 * Ations for controlling the step settings modal.
 */
export class StepSettingsActions extends ActionClass {
    public setupModal: (stepIndex: number, title: string, selectedSourceType: string, selectedSourceStep: number | null, selectedInputDataType: string, selectedTransformType: string) => void = null as any;
    public setVisibility: (visible: boolean) => void = null as any;
    public setEditStepIndex: (index: number) => void = null as any;
    public setEnteredTitle: (title: string) => void = null as any;
    public setSelectedSourceType: (selectedSourceType: string) => void = null as any;
    public setSelectedSourceStep: (selectedSourceStep: number) => void = null as any;
    public setSelectedInputDataType: (selectedInputDataType: string) => void = null as any;
    public setSelectedTransformType: (selectedTransformType: string) => void = null as any;
}