import { DataType } from "./DataType";
import { IDataTypeInfo } from "./IDataTypeInfo";

export class XmlDataTypeInfo implements IDataTypeInfo {
    public readonly dataType: DataType = DataType.Xml;

    public readonly canFormat: boolean = true;

    readonly displayName: string = "XML";

    readonly editorType: string = "xml";

    public format(data: string): string {
        const domParser = new DOMParser();
        const serializer = new XMLSerializer();
        const doc = domParser.parseFromString(data, "text/xml");
        return serializer.serializeToString(doc);
    }
}