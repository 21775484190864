import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class JSONPointerQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "/array/1/dictionary/a";
    }

    public name: QueryType = QueryType.JSONPointer;

    public displayName: string = "JSONPointer (Manuel Stofer 0.6.0 / rfc6901)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Json;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://www.npmjs.com/package/json-pointer";

    public editorMode: string = "text";
}