import * as React from "react";

export class Ad extends React.Component<{}, {}> {

    public componentDidMount() {
        try {
            const anywindow = window as any;
            anywindow.adsbygoogle = anywindow.adsbygoogle || [];
            anywindow.adsbygoogle.push({});
        }
        catch (error) {
            console.log(error);
        }
    }

    public render() {
        return (
            <div style={{width:"100%", height:"100%"}}>
                <ins className="adsbygoogle"
                    style={{"display":"block"}}
                    data-ad-client="ca-pub-6186385299611455"
                    data-ad-slot="6350629520"
                    data-ad-format="rectangle"
                    data-full-width-responsive="true"></ins>
            </div>
        );
    }
}
