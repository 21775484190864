/**
 * The root state type for the application.
 */
export interface IAppState {
    drawerOpen: boolean;

    horizontalLayout: boolean;

    cookieAlertVisible: boolean;
}

export const InitialState: IAppState = {
    drawerOpen: true,

    horizontalLayout: true,

    cookieAlertVisible: true
}