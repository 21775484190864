import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class SimpleXsltQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "<?xml version=\"1.0\"?>\n<xsl:stylesheet version=\"1.0\" xmlns:xsl=\"http://www.w3.org/1999/XSL/Transform\">\n    <xsl:output indent=\"yes\"/>\n    <xsl:template match=\"/\">\n        <output/>\n    </xsl:template>\n</xsl:stylesheet>";
    }

    public name: QueryType = QueryType.SimpleXSLT;

    public displayName: string = "XSLT (Browser native)";

    public readonly inputDataType: DataType = DataType.Xml;

    public readonly outputDataType: DataType = DataType.Xml;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return this.sampleQuery;
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://www.w3schools.com/xml/xsl_transformation.asp";

    public editorMode: string = "xml";
}