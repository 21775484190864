import * as React from "react";

import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 20,
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

export function CreditsPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.appBarSpacer} />
            <h1>Credits</h1>

            <hr />

            <p>This website has been built using these great libraries.</p>

            <ul className="list-unstyled">
                <li><a target="_blank" rel="noopener noreferrer" href="https://material-ui.com">Material-UI</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.freeprivacypolicy.org">FreePrivacyPolicy.org</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/jsonpath">jsonpath</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/jsonpath-plus">JSONPath Plus</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://goessner.net/articles/JsonPath/">goessner.net</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/jsonata">JSONata</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/json-pointer">JSON Pointer</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/dfilatov/jspath/">JSPath</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/plexium/jpath">jpath</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://lodash.com/">lodash.com</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://underscorejs.org/">underscorejs.org</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/xml2js">xml2js</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://codemirror.net/">codemirror.net</a></li>
            </ul>
        </div>
    );
}
