import { Experience, OrUndefined, createMemoizedFunction } from "../../infrastructure";
import { ITransformSettingsState, InitialState } from "../../state/transformSettings/ITransformSettingsState";

import { Dispatch } from "redux";
import { ITransformSettingsProps } from "../../components/transformSettings/ITransformSettingsProps";
import { QueryInfoProviderInstance } from "../../businessLogic/queryTypes/QueryInfoProvider";
import { TransformSettingsActions } from "../../state/transformSettings/TransformSettingsActions";
import { TransformSettingsReducer } from "../../state/transformSettings/TransformSettingsReducer";

type Settings = {
    actionMap: TransformSettingsActions,
    children: {},
    parentCalls: {
        onSelectedTransformTypeUpdated: (stepIndex: number, transformType: string) => void
    }
};

/**
 * Contains the UI logic to control the transform setings modal.
 */
export class TransformSettingsExperience extends Experience<ITransformSettingsState, ITransformSettingsProps, Settings> {
    /**
     * Initializes a new instance of the `TransformSettingsExperience` class.
     * @param namespace A namespace to differentiate this experience from siblings.
     */
    constructor(
        namespace: string) {
        super(namespace, TransformSettingsActions, TransformSettingsReducer, InitialState, [], []);

        this.buildTransformTypes = createMemoizedFunction(this.buildTransformTypes);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onSelectedTransformTypeChanged = this.onSelectedTransformTypeChanged.bind(this);
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapStateToProps(state: ITransformSettingsState, ownProps: {}): OrUndefined<ITransformSettingsProps> {
        const transformTypes = this.buildTransformTypes();
        return {
            visible: state.visible,
            transformTypes: transformTypes,
            selectedTransform: state.selectedTransformType || "",
            onSelectedTransformTypeChanged: undefined,
            onCancelClicked: undefined,
            onSaveClicked: undefined,
        };
    }

    /**
     * Maps the given state to props.
     * @param state The state to map to props.
     * @param ownProps Properties passed to the connected component.
     */
    public mapDispatchToProps(dispatch: Dispatch, ownProps: {}): OrUndefined<ITransformSettingsProps> {
        return {
            visible: undefined,
            transformTypes: undefined,
            selectedTransform: undefined,
            onSelectedTransformTypeChanged: this.onSelectedTransformTypeChanged,
            onCancelClicked: this.onCancelClicked,
            onSaveClicked: this.onSaveClicked,
        };
    }

    /**
     * Make the modal visible.
     */
    public showModal(stepIndex: number, selectedTransformType: string) {
        this.actions.setStepIndex(stepIndex);
        this.actions.setSelectedTransformType(selectedTransformType);
        this.actions.setVisibility(true);
    }

    /**
     * Build an array of transform types from the list of registered query info providers.
     */
    private buildTransformTypes(): { key: string, description: string }[] {
        return QueryInfoProviderInstance.queryInfoArray.map((queryInfo) => {
            return { key: queryInfo.name, description: queryInfo.displayName };
        });
    }

    /**
     * Event handler for selected transform dropdown value changed.
     */
    private onSelectedTransformTypeChanged(transformType: string): void {
        this.actions.setSelectedTransformType(transformType);
    }

    /**
     * Event handler for save button click.
     */
    private onSaveClicked(): void {
        const state = this.getState();
        this.parentCalls.onSelectedTransformTypeUpdated(state.stepIndex === null ? 0 : state.stepIndex, state.selectedTransformType || "");
        this.actions.setVisibility(false);
    }

    /**
     * Event handler for cancel button click.
     */
    private onCancelClicked(): void {
        this.actions.setVisibility(false);
    }
}