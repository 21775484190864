export function createMemoizedFunction<TFunction extends (...params: any[]) => any>(inputFunction: TFunction): TFunction {
    let cacheKey: any[] | null = null;
    let cache: ReturnType<TFunction> | null = null;
    return function() {
        if (cacheKey != null && cacheKey.length === arguments.length) {
            let match = true;
            for (let i = 0; i < cacheKey.length; i++) {
                match = match && cacheKey[i] === arguments[i];
            }

            if (match) {
                return cache;
            }
        }

        cacheKey = Array.from(arguments);
        cache = inputFunction.apply(null, cacheKey);

        return cache;
    } as TFunction;
}

export function createAllInputsMemoizedFunction<TFunction extends (...params: any[]) => any>(inputFunction: TFunction): TFunction {
    let cacheKeys: any[][] = [];
    let cache: ReturnType<TFunction>[] = [];
    return function() {
        let foundEntry = false;
        let entryIndex = 0;

        // Try and find a matching cache key.
        for (let i = 0; i < cacheKeys.length; i++) {
            let cacheKey = cacheKeys[i];
            if (cacheKey.length === arguments.length) {
                let match = true;
                for (let i = 0; i < cacheKey.length; i++) {
                    match = match && cacheKey[i] === arguments[i];
                }
    
                if (match) {
                    foundEntry = true;
                    entryIndex = i;
                    break;
                }
            }
        }

        // Return the matching entry if we found one.
        if (foundEntry) {
            return cache[entryIndex];
        }
        
        // Create a new cache entry if we did not find a match.
        let cacheKey = Array.from(arguments);
        let cacheEntry = inputFunction.apply(null, cacheKey);
        cacheKeys.push(cacheKey);
        cache.push(cacheEntry);

        return cacheEntry;
    } as TFunction;
}