import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import { ITransformSettingsProps } from './ITransformSettingsProps';
import React from 'react';

export default function TransformSettings(props: ITransformSettingsProps) {

    const transformMenuItems = props.transformTypes.map((item) => {
        return (<MenuItem key={item.key} value={item.key}>{item.description}</MenuItem>);
    });

    return (
        <div>
        <Dialog open={props.visible} onClose={() => props.onCancelClicked()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Transform Settings</DialogTitle>
            <DialogContent>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Transform type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.selectedTransform}
                        onChange={(event) => { props.onSelectedTransformTypeChanged(event.target.value as string); }}
                    >
                        {transformMenuItems}
                    </Select>
                    <FormHelperText>The type of transformation language to use.</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSaveClicked()} color="primary">
                    Save
                </Button>
                <Button onClick={() => props.onCancelClicked()} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
