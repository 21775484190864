export var sampleData: { [key: string]: any } = {
    "jsonpathregex": {
        "queryType": "JSONPath",
        "data": {
            "inputs": [
                {
                    "attributeX": [
                        {
                            "id1": "value11"
                        },
                        {
                            "id1": "value12"
                        }
                    ],
                    "attributeY": [
                        {
                            "id": "valueY1",
                            "attributeZ": [
                                {
                                    "pathZ": "/Path1/Path2[00163E038C2E1EE299C1C394370BCFA0]/Description",
                                    "value": "valueXYZ"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "query": "$.inputs.[*].attributeY.[*].attributeZ[?(new RegExp('^/Path1/Path2.*/Description$').test(@.pathZ))].value"
    },
    "jsonpathtoplevel": {
        "queryType": "JSONPath",
        "data": {
            "name": "Toaster",
            "price": "19.99",
            "store": {
                "retailer": "Ants in My Eyes Johnson",
                "price": "9.99",
                "currency": "USD"
            }
        },
        "query": "$.price"
    },
    "jsonpathwhereselect": {
        "queryType": "JSONPath",
        "data": {
            "References": [
                {
                    "TypeName": "Employees",
                    "Attributes": {
                        "Type": "9t59c6ghn1l4y81nvu7a",
                        "Name": "WH-3"
                    }
                },
                {
                    "TypeName": "Hardware",
                    "Attributes": {
                        "Name": "rz2j8c85kbl3add5vmb5"
                    }
                },
                {
                    "TypeName": "Organization",
                    "Attributes": {
                        "Type": "4qww1im1o6w4xm4ehlkm",
                        "Name": "Training"
                    }
                }
            ]
        },
        "query": "$..References[?(@.TypeName=='Organization')].Attributes"
    },
    "jsonpathwhereselectcomments": {
        "queryType": "JSONPath",
        "data": {
            "id": "12029930209393029_10100748134340048",
            "from": {
                "id": "12029930209393029",
                "name": "Tori Smith"
            },
            "message": "Buy this now",
            "picture": "https://fbexternal-a.akamaihd.net/app_full_proxy.php?app=141861192518680&v=1&size=z&cksum=a0471c1f5895cd22c74474fabc989c7e&src=http%3A%2F%2Fmedia3.policymic.com%2FYTM2OWUwN2Q0MSMvRnlpUTkxZU9DMWtGWFZ6TUNiYWh3RkxveXRjPS8yeDE6MTI4Nng2MjIvMTI4MHg2MjAvZmlsdGVyczpxdWFsaXR5KDcwKS9odHRwOi8vczMuYW1hem9uYXdzLmNvbS9wb2xpY3ltaWMtaW1hZ2VzL2JqNTdvbTZxZGd1N3ZpaGtvcWVrNnlzaTI5bW55dGZqanEwMWhuc3FqYjgxc3dkeGcyN2F6czV0eXV0bWJzZTguanBn.jpg",
            "link": "http://mic.com/articles/101252/this-tiny-box-will-let-you-stay-anonymous-on-the-internet",
            "name": "This Tiny Box Will Let You Stay Anonymous on the Internet",
            "caption": "Mic",
            "description": "A simple and elegant solution to a major technology problem.",
            "icon": "https://fbcdn-photos-d-a.akamaihd.net/hphotos-ak-xpf1/t39.2081-0/10333103_752719651432828_1597152122_n.png",
            "actions": [
                {
                    "name": "Comment",
                    "link": "https://www.facebook.com/12029930209393029/posts/10100748134340048"
                },
                {
                    "name": "Like",
                    "link": "https://www.facebook.com/12029930209393029/posts/10100748134340048"
                }
            ],
            "privacy": {
                "description": "Your friends",
                "value": "ALL_FRIENDS",
                "friends": "",
                "networks": "",
                "allow": "",
                "deny": ""
            },
            "type": "link",
            "status_type": "app_created_story",
            "application": {
                "name": "Mic",
                "namespace": "micmediaapp",
                "id": "141861192518680"
            },
            "created_time": "2014-10-14T14:54:54+0000",
            "updated_time": "2014-10-15T03:55:19+0000",
            "comments": {
                "data": [
                    {
                        "id": "10100748134340048_10100748984636048",
                        "from": {
                            "id": "123094958239849866",
                            "name": "Don Draper"
                        },
                        "message": "Even if I use Tor",
                        "can_remove": true,
                        "created_time": "2014-10-15T03:03:29+0000",
                        "like_count": 0,
                        "user_likes": false
                    },
                    {
                        "id": "10100748134340048_10100749036726658",
                        "from": {
                            "id": "12029930209393029",
                            "name": "Tori Smith"
                        },
                        "message": "Yes this can go with you and I think it works for all apps outside of TOR browser",
                        "can_remove": true,
                        "created_time": "2014-10-15T03:55:19+0000",
                        "like_count": 0,
                        "user_likes": false
                    },
                    {
                        "id": "10100748134340048_1010074901234658",
                        "from": {
                            "id": "12029930209393029",
                            "name": "Tori Smith"
                        },
                        "message": "Second Text",
                        "can_remove": true,
                        "created_time": "2014-10-15T03:55:19+0000",
                        "like_count": 0,
                        "user_likes": false
                    }
                ],
                "paging": {
                    "cursors": {
                        "after": "WTI5dGJXVnVkRjlqZFhKemIzSTZNVEF4TURBM05Ea3dNelkzTWpZMk5UZzZNVFF4TXpNME5UTXhPVG95",
                        "before": "WTI5dGJXVnVkRjlqZFhKemIzSTZNVEF4TURBM05EZzVPRFEyTXpZd05EZzZNVFF4TXpNME1qSXdPVG94"
                    }
                }
            }
        },
        "query": "$.comments.data[?(@.from.name == 'Tori Smith')].message"
    },
    jsonpathselectmultiplefields: {
        "queryType": "JSONPath",
        "data": {
            "store": {
                "book": [
                    {
                        "category": "reference",
                        "author": "Nigel Rees",
                        "title": "Sayings of the Century",
                        "price": 8.95
                    },
                    {
                        "category": "fiction",
                        "author": "Evelyn Waugh",
                        "title": "Sword of Honour",
                        "price": 12.99
                    },
                    {
                        "category": "fiction",
                        "author": "Herman Melville",
                        "title": "Moby Dick",
                        "isbn": "0-553-21311-3",
                        "price": 8.99
                    },
                    {
                        "category": "fiction",
                        "author": "J. R. R. Tolkien",
                        "title": "The Lord of the Rings",
                        "isbn": "0-395-19395-8",
                        "price": 22.99
                    }
                ],
                "bicycle": {
                    "color": "red",
                    "price": 19.95
                }
            }
        },
        "query": "$.store.book[?(@.category=='reference')]['author','title']"
    },
    jsonpathmultilevelfilter: {
        "queryType": "JSONPath",
        "data": [
            {
                "id": 1,
                "name": "Tytu³",
                "isNew": true,
                "description": "Opis dzia³u",
                "courses": [
                    {
                        "id": 1,
                        "isNew": true,
                        "price": 120,
                        "time": 50,
                        "assumptions": "Assumption 1",
                        "knowledge": "Wiedza",
                        "domainId": 1,
                        "programs": []
                    }
                ]
            }
        ],
        "query": "$[?(@.id==1)].courses[?(@.id==1)]"
    },
    "lodashmultilevelfilter": {
        "queryType": "Lodash",
        "data": [
            {
                "id": 1,
                "name": "Tytu³",
                "isNew": true,
                "description": "Opis dzia³u",
                "courses": [
                    {
                        "id": 1,
                        "isNew": true,
                        "price": 120,
                        "time": 50,
                        "assumptions": "Assumption 1",
                        "knowledge": "Wiedza",
                        "domainId": 1,
                        "programs": []
                    }
                ]
            }
        ],
        "query": "_(input).chain()\n    .filter(function(x) { return x.id == 1; })\n    .first()\n    .result('courses')\n    .filter(function(x) { return x.id == 1; })\n    .first()\n    .value()"
    },
    "jsonpathfilterbyarraycontents": {
        "queryType": "JSONPath",
        "data": {
            "Books": {
                "History": [
                    {
                        "badge": "y",
                        "Tags": [
                            "Indian",
                            "Culture"
                        ],
                        "ISBN": "xxxxxxx",
                        "id": 1,
                        "name": "Cultures in India"
                    },
                    {
                        "badge": "y",
                        "Tags": [
                            "Pre-historic",
                            "Creatures"
                        ],
                        "ISBN": "xxxxxxx",
                        "id": 1,
                        "name": "Pre-historic Ages"
                    }
                ]
            }
        },
        "query": "$.Books.History[?(@.Tags.indexOf('Indian') != -1)]"
    },
    "jsonpathlastinarray": {
        "queryType": "JSONPath",
        "data": [
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 0
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 1
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 2
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 3
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 4
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 5
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "test",
                "partition": 0,
                "offset": 6
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "Hi Test",
                "partition": 0,
                "offset": 7
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "Hi Test",
                "partition": 0,
                "offset": 8
            },
            {
                "topic": "inputTopic",
                "key": "0",
                "message": "Hi Test",
                "partition": 0,
                "offset": 9
            }
        ],
        "query": "$.[(@.length-1)]"
    },
    "jsonpathfilterallbypropertyvalue": {
        "queryType": "JSONPath",
        "data": [
            {
                "id": 1,
                "l": "a",
                "sub": []
            },
            {
                "id": 2,
                "l": "b",
                "sub": [
                    {
                        "id": 4,
                        "l": "d"
                    },
                    {
                        "id": 5,
                        "l": "e"
                    },
                    {
                        "id": 6,
                        "l": "f",
                        "sub": [
                            {
                                "id": 7,
                                "l": "g"
                            }
                        ]
                    }
                ]
            },
            {
                "id": 3,
                "l": "c",
                "sub": []
            }
        ],
        "query": "$..[?(@.id==7)]"
    }
};