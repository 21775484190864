import { DataType } from "../../dataTypes/DataType";
import { IQueryInfo } from "./IQueryInfo";
import { QueryType } from "../QueryType";

export class JSONPathDChesterQueryInfo implements IQueryInfo {

    private sampleQuery: string;

    public constructor() {
        this.sampleQuery = "$.array[?(@.key==2)].dictionary.a";
    }

    public name: QueryType = QueryType.JSONPathDChester;

    public displayName: string = "JSONPath (dchester 1.0.2)";

    public readonly inputDataType: DataType = DataType.Json;

    public readonly outputDataType: DataType = DataType.Json;

    public readonly hasQuery: boolean = true;

    public getSampleQuery(): string {
        return this.sampleQuery;
    }

    public getSelectAllQuery(): string {
        return "$";
    }

    public setSampleQuery(query: string): void {
        this.sampleQuery = query;
    }

    public helpUrl: string = "https://www.npmjs.com/package/jsonpath";

    public editorMode: string = "text";
}
